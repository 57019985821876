import React, { useReducer, useRef, useEffect } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import { Manager, Reference, Popper } from 'react-popper';
import classNames from 'classnames';

import Input from './input.js';
import Menu from './menu.js';

export const actionTypes = {
  TOGGLE_DROPDOWN_MENU: 'TOGGLE_DROPDOWN_MENU',
  TOGGLE_INPUT_FOCUS: 'TOGGLE_INPUT_FOCUS'
};

const initialState = {
  isDropdownMenuOpen: false,
  isInputFocused: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DROPDOWN_MENU: {
      return R.assoc('isDropdownMenuOpen', action.payload, state);
    }
    case actionTypes.TOGGLE_INPUT_FOCUS: {
      return R.assoc('isInputFocused', action.payload, state);
    }
    default:
      return state;
  }
};

const styles = {
  container: {
    position: 'relative',
    width: '32rem',

    '&$open': {
      zIndex: 2
    }
  },
  open: {}
};

const Dropdown = ({
  value,
  options,
  onChange,
  input,
  menu,
  isDropdownMenuOpen,
  popperKey,
  inputProps,
  menuProps,
  popperProps = {},
  noDataTitle,
  className,
  isMenuAlwaysOpen,
  classes
} = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const popperRef = useRef();

  useEffect(() => {
    popperRef.current && popperRef.current.scheduleUpdate();
  }, [popperKey]);

  const selectedOption = R.find(option => option.key === value, options);

  const renderInput = input || (props => <Input {...props} />);
  const renderMenu = menu || (props => <Menu {...props} />);

  const isDropdownMenuOpenValue =
    isMenuAlwaysOpen ||
    (state.isInputFocused &&
      (R.isNil(isDropdownMenuOpen)
        ? state.isDropdownMenuOpen
        : isDropdownMenuOpen));

  return (
    <div
      className={classNames(
        classes.container,
        {
          [classes.open]: isDropdownMenuOpenValue
        },
        className
      )}
    >
      <Manager>
        <Reference>
          {({ ref }) => {
            return renderInput({
              ...inputProps,
              forwardedRef: ref,
              onClick: () => {
                if (R.isNil(isDropdownMenuOpen)) {
                  dispatch({
                    type: actionTypes.TOGGLE_DROPDOWN_MENU,
                    payload: !state.isDropdownMenuOpen
                  });
                }
              },
              selectedOption,
              isDropdownMenuOpen: isDropdownMenuOpenValue,
              onFocus: e => {
                dispatch({
                  type: actionTypes.TOGGLE_INPUT_FOCUS,
                  payload: true
                });

                inputProps.onFocus && inputProps.onFocus(e);
              },
              onBlur: e => {
                dispatch({
                  type: actionTypes.TOGGLE_INPUT_FOCUS,
                  payload: false
                });

                dispatch({
                  type: actionTypes.TOGGLE_DROPDOWN_MENU,
                  payload: false
                });

                inputProps.onBlur && inputProps.onBlur(e);
              }
            });
          }}
        </Reference>
        {isDropdownMenuOpenValue && (
          <Popper placement="bottom" {...popperProps}>
            {({ ref, style, placement, scheduleUpdate }) => {
              popperRef.current = { scheduleUpdate };

              return renderMenu({
                ...menuProps,
                forwardedRef: ref,
                style,
                placement,
                options,
                noDataTitle,
                onSelect: option => {
                  onChange(option.key, option);

                  if (R.isNil(isDropdownMenuOpen)) {
                    dispatch({
                      type: actionTypes.TOGGLE_DROPDOWN_MENU,
                      payload: false
                    });
                  }
                },
                // TODO: Fast and easy solution for input focuse loss preventing. If will not work change to react-day-picker solution (blur and timeout).
                onMouseDown: e => {
                  e.preventDefault();
                }
              });
            }}
          </Popper>
        )}
      </Manager>
    </div>
  );
};

Dropdown.defaultProps = {
  inputProps: {},
  menuProps: {}
};

export default injectSheet(styles)(Dropdown);
