import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import CheckMark from './checkMark.js';
import { CardsListLayouts, CardLayouts } from './shared.js';

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    width: '153px',
    backgroundColor: theme.cardsListCardBackgroundColor,
    borderRadius: '15px',
    textAlign: 'center',
    padding: '35px 0 30px 0',
    cursor: 'pointer',

    '&$compact': {
      borderRadius: '0'
    }
  },
  image: {
    maxWidth: '100%',
    padding: '0 10px'
  },
  imageFullWidth: {
    padding: '0'
  },
  title: {
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    padding: '0 10px',
    marginTop: '12px'
  },
  description: {
    fontSize: TextFontSizes.xxSmallPx,
    lineHeight: TextFontSizes.xxSmallLineHeightPx,
    padding: '0 10px',
    marginTop: '5px'
  },
  checkMark: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '-5px',
    right: '-5px',

    '&$compact': {
      width: '24px',
      height: '24px',
      top: '10px',
      right: '10px'
    }
  },
  compact: {}
}));

export const Card = ({
  id,
  isSelected,
  onSelect,
  imageSrc,
  imageStyle,
  title,
  description,
  cardsListLayout,
  layout,
  className
}) => {
  const classes = useStyles();

  const imageClassName = classNames(classes.image, {
    [classes.imageFullWidth]: layout === CardLayouts.fullWidth
  });

  return (
    <div
      className={classNames(
        classes.container,
        { [classes.compact]: cardsListLayout === CardsListLayouts.compact },
        className
      )}
      onClick={onSelect}
      data-testid={`card-${id}`}
    >
      {isSelected && (
        <CheckMark
          className={classNames(classes.checkMark, {
            [classes.compact]: cardsListLayout === CardsListLayouts.compact
          })}
          data-testid={'card-selected-icon'}
        />
      )}
      <img
        className={imageClassName}
        src={imageSrc}
        style={imageStyle}
        alt={title}
      />
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

Card.defaultProps = {
  layout: CardLayouts.default,
  imageStyle: {}
};

export default Card;
