import create from 'zustand';

export const useUiStore = create(set => ({
  isTableOpen: true,
  toggleTable: () => {
    set(state => ({
      isTableOpen: !state.isTableOpen
    }));
  }
}));
