import React, { Fragment, cloneElement } from 'react';
import * as R from 'ramda';

import { toAst } from './utils/i18n.js';
import { NodeType } from './constants/i18n.js';

const mapIndexed = R.addIndex(R.map);

const I18n = ({ text, textClassName, replaceParams }) => {
  if (text == null) {
    return null;
  }

  let ast = toAst(replaceParams, text);
  return (
    <Fragment>
      {mapIndexed(
        (node, index) =>
          node.type === NodeType.text ? (
            <span key={index} className={textClassName}>
              {node.value}
            </span>
          ) : (
            cloneElement(node.value, { key: index })
          ),
        ast
      )}
    </Fragment>
  );
};

export default I18n;
