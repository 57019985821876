export const Colors = {
  black: '#000000',
  white: '#ffffff',
  blue25: '#164152',
  blue50: '#1a242a',
  blue50_07: 'rgba(26, 36, 42, 0.7)',
  blue75: '#1e2a30',
  blue100: '#233037',
  blue150: '#0bb3f3',
  blue150_05: 'rgba(11, 179, 243, 0.5)',
  blue200: '#2a3840',
  blue250: '#222e35',
  blue300: '#28363d',
  blue350: '#3c4a54',
  blue400: '#04739e',
  blue500: '#173845',
  blue600: '#0BB3F3',
  gray50: '#6a8595',
  gray50_06: 'rgba(106, 133, 149, 0.6)',
  gray100: '#707070',
  gray150: '#2b373f',
  gray200: '#526874',
  gray300: '#3C4A54',
  red50: '#f25b5b',
  red50_05: 'rgba(242, 91, 91, 0.5)',
  red100: '#863f42',
  green50: '#41b851',
  green50_05: 'rgba(65, 184, 81, 0.5)',
  green100: '#41bf50',
  green250: '#27AE60',
  green250_04: 'rgba(39, 174, 96, 0.4)',
  green300: '#219653',
  yellow50: '#ffb01c',
  yellow50_05: 'rgba(255, 176, 28, 0.5)',
  yellow100: '#8d6a23'
};
