import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import { useMediaQuery } from 'react-responsive';

import MainLayout from 'features/shared/components/mainLayout/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import PoweredBy from 'features/shared/components/mainLayout/poweredBy.js';
import { useFormStore } from '../services/formStore.js';
import SettingsCard from './settingsCard.js';
import ReturnChartCard from './returnChartCard.js';
import ExpectedResultCard from './expectedResultCard';
import ResultsTableCard from './resultsTableCard';
import Card from './card.js';

const Margin = 10;

const useStyles = createUseStyles({
  pageContainer: {},
  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',

    '& > div:first-child': {
      marginRight: `${Margin}px`
    },
    '& > div:last-child': {
      marginLeft: `${Margin}px`
    }
  },
  desktopColumn: {
    width: `calc(50% - ${Margin}px)`,
    alignSelf: 'stretch'
  },
  firstDesktopColumn: {
    alignSelf: 'flex-start',
    '& > div:first-child': {
      marginBottom: '20px'
    }
  },
  poweredByLabel: {
    marginTop: '5px'
  },
  resultsCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  resultsCardExpectedResult: {
    marginBottom: '30px'
  },
  laptopRow: {
    '& + &': {
      marginTop: '20px'
    }
  }
});

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV

const Calculator = () => {
  const classes = useStyles();
  const leftRef = React.useRef();
  const [leftHeight, setLeftHeight] = React.useState(null);
  const customerConfig = React.useContext(CustomerConfigContext);
  const formStoreState = useFormStore.getState();
  const isDesktop = useMediaQuery({
    minWidth: 1025
  });
  // const isLaptop = useMediaQuery({
  //   maxWidth: 1024,
  //   minWidth: 769
  // });
  // const isTablet = useMediaQuery({
  //   maxWidth: 768,
  //   minWidth: 481
  // });
  const isMobile = useMediaQuery({
    maxWidth: 480
  });

  const onResize = () => {
    if (R.isNil(leftRef.current)) {
      return;
    }

    const leftCardHeight = leftRef.current.clientHeight;
    setLeftHeight(leftCardHeight);
  };

  React.useEffect(() => {
    formStoreState.changeRiskProfile(customerConfig.defaultRiskProfileId);
  }, []);

  if (isDesktop) {
    return (
      <MainLayout pageContainer={classes.pageContainer}>
        <div className={classes.desktopWrapper}>
          <div
            ref={leftRef}
            className={classNames(
              classes.desktopColumn,
              classes.firstDesktopColumn
            )}
          >
            <Card>
              <SettingsCard isMobile={false} />
            </Card>
            <Card>
              <ReturnChartCard />
            </Card>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
          </div>
          <div className={classes.desktopColumn}>
            <Card
              className={classes.resultsCard}
              style={{
                height: R.isNil(leftHeight) ? null : `${leftHeight}px`
              }}
            >
              <ExpectedResultCard
                className={classes.resultsCardExpectedResult}
                isMobile={false}
              />
              <ResultsTableCard isMobile={false} />
            </Card>
          </div>
        </div>
        <div className={classes.poweredByLabel}>
          <PoweredBy />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout pageContainer={classes.pageContainer}>
      <div>
        <div className={classes.laptopRow}>
          <Card>
            <SettingsCard isMobile={isMobile} />
          </Card>
        </div>
        <div className={classes.laptopRow}>
          <Card>
            <ExpectedResultCard isMobile={isMobile} />
          </Card>
        </div>
        <div className={classes.laptopRow}>
          <Card>
            <ReturnChartCard />
          </Card>
        </div>
        <div className={classes.laptopRow}>
          <Card>
            <ResultsTableCard isMobile={isMobile} />
          </Card>
        </div>
      </div>
      <div className={classes.poweredByLabel}>
        <PoweredBy />
      </div>
    </MainLayout>
  );
};

export default Calculator;
