import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { FontWeights } from 'features/shared/constants/fonts.js';

const styles = theme => {
  return {
    link: {
      fontWeight: FontWeights.medium,
      color: theme.primaryColor,
      textDecoration: 'none',
      cursor: 'pointer'
    }
  };
};

const Link = ({ children, className, classes, to, ...rest }) => {
  return (
    <a {...rest} className={classNames(classes.link, className)}>
      {children}
    </a>
  );
};

export default injectSheet(styles)(Link);
