import * as R from 'ramda';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import config from 'config/index.js';
import {
  getQAuthApiAccessToken,
  setQAuthApiAccessToken,
  removeQAuthApiAccessToken
} from 'features/shared/session/storage.js';

const createAxios = config => {
  const newInstance = axios.create(config);

  const UNAUTHORIZED = 401;
  newInstance.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        removeQAuthApiAccessToken();
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
};

export const savingCalculatorApi = createAxios({
  baseURL: config.REACT_APP_CALCULATOR_API_URL
});

export const qAuthApi = axios.create({
  baseURL: config.REACT_APP_QAUTH_API_URL
});

export const customersApi = axios.create({
  baseURL: config.REACT_APP_CUSTOMERS_API_URL
});

export const getQAuthAccessToken = async (customerId, cancelToken) => {
  const accessToken = getQAuthApiAccessToken();

  if (!R.isNil(accessToken) && accessToken.customerId === customerId) {
    return accessToken.qAuthAccessToken;
  }

  const auth = await qAuthApi.post(
    '/auth/token/open',
    {
      application: 'saving-calculator',
      customer_id: customerId
    },
    {
      cancelToken
    }
  );

  setQAuthApiAccessToken(
    {
      qAuthAccessToken: auth.data.token,
      customerId
    },
    moment
      .utc(auth.data.expire_utc)
      .toDate()
      .getTime()
  );

  return auth.data.token;
};

export const getUc1SavingCalculations = (data, accessToken, cancelToken) => {
  return savingCalculatorApi.get(`/calculator/simple/`, {
    params: data,
    headers: { Authorization: accessToken },
    cancelToken
  });
};

export const getUc6SavingCalculations = (data, accessToken, cancelToken) => {
  return savingCalculatorApi.get(`/calculator/bound/`, {
    params: data,
    headers: { Authorization: accessToken },
    cancelToken
  });
};

export const getCustomerConfig = (
  accessToken,
  customerId,
  language,
  cancelToken
) => {
  return customersApi.get(
    queryString.stringifyUrl(
      {
        url: `customers/${customerId}`,
        query: { language }
      },
      {
        skipNull: true
      }
    ),
    {
      cancelToken,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};
