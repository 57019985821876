import create from 'zustand';

export const useFormStore = create(set => ({
  saving: 1000,
  holding: 100000,
  returns: 5,
  horizon: 10,
  changeSaving: value => {
    set({
      saving: value
    });
  },
  changeHolding: value => {
    set({
      holding: value
    });
  },
  changeReturns: value => {
    set({
      returns: value
    });
  },
  changeHorizon: value => {
    set({
      horizon: value
    });
  }
}));
