import React, { Fragment } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import Icon from 'features/shared/components/icon/index.js';
import Dropdown from 'features/shared/components/dropdown/index.js';
import { i18n } from 'i18n/index.js';
import { getTotalPagesNumber } from 'features/shared/utils/table.js';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    marginTop: '1.6rem'
  },
  pageSizeLabel: {
    marginRight: '1.6rem'
  },
  pageSizeDropdown: {
    width: '12.5rem',
    marginRight: '2.4rem'
  },
  pageSizeDropdownInput: {
    height: 'calc(3.2rem + 4px)',
    lineHeight: '1.6rem'
  },
  pageSizeDropdownInputIcon: {
    lineHeight: '1.6rem'
  },
  pageSizeDropdownMenuOffsetTop: {
    marginBottom: '-1.6rem',
    paddingBottom: '2.4rem'
  },
  pageSizeDropdownMenuOffsetBottom: {
    marginTop: '-1.6rem',
    paddingTop: '2.4rem'
  },
  pageSizeDropdownMenuOption: {
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem'
  },
  itemsNumberInfo: {
    marginRight: '2.4rem',

    '&:last-child': {
      marginRight: '0'
    }
  },
  navigationArrowIcon: {
    alignSelf: 'center',
    cursor: 'pointer',
    fontSize: '2.5rem',
    lineHeight: '1',
    color: Colors.blue150,

    '&$hidden': {
      visibility: 'hidden'
    }
  },
  navigationArrowIconLeft: {
    marginRight: '0.4rem'
  },
  navigationArrowIconRight: {
    marginLeft: '0.4rem'
  },
  hidden: {},
  pageNumberButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.2rem',
    height: '3.2rem',
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: Colors.blue400
    },

    '&$active': {
      color: Colors.blue50,
      backgroundColor: Colors.blue150
    },

    '& + &': {
      marginLeft: '0.4rem'
    },
    '$pageNumberButtonsDivider + &': {
      marginLeft: '0.4rem'
    }
  },
  active: {},
  pageNumberButtonsDivider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3.2rem',
    height: '3.2rem',

    '& + &': {
      marginLeft: '0.4rem'
    },
    '$pageNumberButton + &': {
      marginLeft: '0.4rem'
    }
  }
};

const PageNumberButtons = ({
  pageNumber,
  totalPagesNumber,
  changePageNumber,
  classes
}) => {
  const paginationSize = 7;
  const edgeRangeSize = paginationSize - 2;
  const middleRangeSize = paginationSize - 4;

  const middleRangeSizeMinValue = 3;
  if (middleRangeSize < middleRangeSizeMinValue) {
    throw Error(
      `Middle range size min value is ${middleRangeSizeMinValue}. Otherwise navigation will be inconvenient for user.`
    );
  }

  let pageNumberButtons;
  if (totalPagesNumber <= paginationSize) {
    pageNumberButtons = R.map(
      pn => (
        <div
          className={classNames(classes.pageNumberButton, {
            [classes.active]: pn === pageNumber
          })}
          key={pn}
          onClick={() => changePageNumber(pn)}
        >
          {pn + 1}
        </div>
      ),
      R.range(0, totalPagesNumber)
    );
  } else if (pageNumber < edgeRangeSize - 1) {
    pageNumberButtons = (
      <Fragment>
        {R.map(
          pn => (
            <div
              className={classNames(classes.pageNumberButton, {
                [classes.active]: pn === pageNumber
              })}
              key={pn}
              onClick={() => changePageNumber(pn)}
            >
              {pn + 1}
            </div>
          ),
          R.range(0, edgeRangeSize)
        )}
        <div className={classes.pageNumberButtonsDivider}>{'...'}</div>
        <div
          className={classNames(classes.pageNumberButton, {
            [classes.active]: totalPagesNumber - 1 === pageNumber
          })}
          onClick={() => changePageNumber(totalPagesNumber - 1)}
        >
          {totalPagesNumber}
        </div>
      </Fragment>
    );
  } else if (pageNumber >= totalPagesNumber - edgeRangeSize + 1) {
    pageNumberButtons = (
      <Fragment>
        <div
          className={classNames(classes.pageNumberButton, {
            [classes.active]: 0 === pageNumber
          })}
          onClick={() => changePageNumber(0)}
        >
          {1}
        </div>
        <div className={classes.pageNumberButtonsDivider}>{'...'}</div>
        {R.map(
          pn => (
            <div
              className={classNames(classes.pageNumberButton, {
                [classes.active]: pn === pageNumber
              })}
              key={pn}
              onClick={() => changePageNumber(pn)}
            >
              {pn + 1}
            </div>
          ),
          R.range(totalPagesNumber - edgeRangeSize, totalPagesNumber)
        )}
      </Fragment>
    );
  } else {
    const middleRangeSizeWithoutCurrentPage = middleRangeSize - 1;
    const rangeFrom =
      pageNumber - Math.floor(middleRangeSizeWithoutCurrentPage / 2);
    const rangeTo =
      pageNumber + Math.ceil(middleRangeSizeWithoutCurrentPage / 2) + 1;

    pageNumberButtons = (
      <Fragment>
        <div
          className={classNames(classes.pageNumberButton, {
            [classes.active]: 0 === pageNumber
          })}
          onClick={() => changePageNumber(0)}
        >
          {1}
        </div>
        <div className={classes.pageNumberButtonsDivider}>{'...'}</div>
        {R.map(
          pn => (
            <div
              className={classNames(classes.pageNumberButton, {
                [classes.active]: pn === pageNumber
              })}
              key={pn}
              onClick={() => changePageNumber(pn)}
            >
              {pn + 1}
            </div>
          ),
          R.range(rangeFrom, rangeTo)
        )}
        <div className={classes.pageNumberButtonsDivider}>{'...'}</div>
        <div
          className={classNames(classes.pageNumberButton, {
            [classes.active]: totalPagesNumber - 1 === pageNumber
          })}
          onClick={() => changePageNumber(totalPagesNumber - 1)}
        >
          {totalPagesNumber}
        </div>
      </Fragment>
    );
  }

  return pageNumberButtons;
};

const Pagination = ({
  pageSize,
  pageNumber,
  totalItemsNumber,
  changePageNumber,
  changePageSize,
  classes
}) => {
  if (R.isNil(pageSize) || R.isNil(pageNumber) || R.isNil(totalItemsNumber)) {
    return null;
  }

  const arePageNumberButtonsShown = pageSize < totalItemsNumber;

  const totalPagesNumber = getTotalPagesNumber(totalItemsNumber, pageSize);
  const isFirstPage = pageNumber === 0;
  const isLastPage = pageNumber === totalPagesNumber - 1;

  return (
    <div className={classes.container}>
      <div className={classes.pageSizeLabel}>{`${i18n(
        'table.rowsPerPage'
      )}:`}</div>
      <Dropdown
        inputProps={{
          className: classes.pageSizeDropdownInput,
          iconClassName: classes.pageSizeDropdownInputIcon
        }}
        menuProps={{
          offsetTopClassName: classes.pageSizeDropdownMenuOffsetTop,
          offsetBottomClassName: classes.pageSizeDropdownMenuOffsetBottom,
          optionClassName: classes.pageSizeDropdownMenuOption
        }}
        className={classes.pageSizeDropdown}
        value={pageSize.toString()}
        onChange={key => changePageSize(Number(key))}
        options={[
          {
            key: '10',
            title: '10'
          },
          {
            key: '30',
            title: '30'
          },
          {
            key: '50',
            title: '50'
          },
          {
            key: '100',
            title: '100'
          }
        ]}
      />
      <div className={classes.itemsNumberInfo}>
        {i18n('table.rangeOfTotal')
          .replace('{0}', Math.min(totalItemsNumber, pageNumber * pageSize + 1))
          .replace(
            '{1}',
            Math.min(totalItemsNumber, pageNumber * pageSize + pageSize)
          )
          .replace('{2}', totalItemsNumber)}
      </div>
      {arePageNumberButtonsShown && (
        <Fragment>
          <Icon
            className={classNames(
              classes.navigationArrowIcon,
              classes.navigationArrowIconLeft,
              {
                [classes.hidden]: isFirstPage
              }
            )}
            onClick={
              isFirstPage ? null : () => changePageNumber(pageNumber - 1)
            }
            type="chevron_left"
          />
          <PageNumberButtons
            pageNumber={pageNumber}
            totalPagesNumber={totalPagesNumber}
            changePageNumber={changePageNumber}
            classes={classes}
          />
          <Icon
            className={classNames(
              classes.navigationArrowIcon,
              classes.navigationArrowIconRight,
              {
                [classes.hidden]: isLastPage
              }
            )}
            onClick={isLastPage ? null : () => changePageNumber(pageNumber + 1)}
            type="chevron_right"
          />
        </Fragment>
      )}
    </div>
  );
};

export default injectSheet(styles)(Pagination);
