import React, { Fragment } from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import Table from 'features/shared/components/table/index.js';
import I18n from 'features/shared/components/i18n/index.js';
import Link from 'features/shared/components/link/index.js';
import { useDataStore } from '../services/dataStore.js';
import { useUiStore } from '../services/uiStore.js';

const indexedMap = R.addIndex(R.map);
const padding = 20;

const useStyles = createUseStyles(theme => ({
  card: {
    backgroundColor: theme.cardBackgroundColor,
    boxShadow: '0px 0px 20px rgba(38, 50, 56, 0.08)',
    padding: padding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    flex: '0 0 auto'
  },
  table: {
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '10px'
  },
  valueContainer: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    paddingBottom: '30px',
    display: 'flex',
    alignItems: 'baseline',

    '& $valueContainerNumber': {
      color: theme.primaryColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.bold,
      fontSize: TextFontSizes.xxLargePx,
      lineHeight: TextFontSizes.xxLargeLineHeightPx
    }
  },
  valueContainerNumber: {
    flex: '0 1 auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  valueContainerCurrency: {
    flex: '0 0 auto',
    paddingLeft: '10px'
  },
  moduleTypeCell: {
    verticalAlign: 'top',
    fontFamily: theme.tabularNumsFontFamily
  },
  moduleTypeContainer: {
    height: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  dateColumn: {
    width: '115px'
  },
  tableToolbar: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: '10px'
  },
  tableToolbarLabel: {
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginRight: '20px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    height: '100%'
  },
  tableTopContainer: {
    height: '100%'
  },
  tableContainer: {
    height: '100%'
  }
}));

const ResultsCard = ({ leftHeight }) => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const dataStore = useDataStore();
  const uiStore = useUiStore();
  const expectedResultValue =
    R.isNil(dataStore.resultsTableData) || R.isEmpty(dataStore.resultsTableData)
      ? null
      : R.last(dataStore.resultsTableData).totalFormatted;

  return (
    <div
      className={classes.card}
      style={{
        height: R.isNil(leftHeight) ? null : `${leftHeight}px`
      }}
    >
      {!R.isNil(dataStore.resultsHorizon) && !R.isNil(expectedResultValue) && (
        <div className={classes.header}>
          <div className={classes.headerText}>
            <I18n
              text={i18n(
                'resultsCard.expectedResult',
                customerConfig.translations
              )}
              replaceParams={{
                '{0}': <span>{dataStore.resultsHorizon}</span>
              }}
            />
          </div>

          <div className={classes.valueContainer}>
            <div
              className={classes.valueContainerNumber}
              title={expectedResultValue}
            >
              {expectedResultValue}
            </div>
            <div className={classes.valueContainerCurrency}>
              {i18n('shared.currency', customerConfig.translations)}
            </div>
          </div>
        </div>
      )}

      {!R.isNil(dataStore.resultsHorizon) && !R.isNil(expectedResultValue) && (
        <div className={classes.tableToolbar}>
          <span className={classes.tableToolbarLabel}>
            {i18n('resultsCard.table.header', customerConfig.translations)}
          </span>
          <Link
            onClick={() => {
              uiStore.toggleTable();
            }}
          >
            {uiStore.isTableOpen
              ? i18n('resultsCard.hideDetails', customerConfig.translations)
              : i18n('resultsCard.showDetails', customerConfig.translations)}
          </Link>
        </div>
      )}

      {uiStore.isTableOpen && !R.isNil(dataStore.resultsTableData) && (
        <div className={classes.table}>
          <Table
            topConatinerClassName={classes.tableTopContainer}
            conatinerClassName={classes.tableContainer}
            header={[
              {
                title: i18n(
                  'resultsCard.table.header.date',
                  customerConfig.translations
                ),
                className: classes.dateColumn
              },
              {
                title: i18n(
                  'resultsCard.table.header.accumulatedDeposits',
                  customerConfig.translations
                )
              },
              {
                title: i18n(
                  'resultsCard.table.header.accumulatedReturns',
                  customerConfig.translations
                )
              },
              {
                title: i18n(
                  'resultsCard.table.header.totalValue',
                  customerConfig.translations
                )
              }
            ]}
            items={indexedMap(field => {
              return {
                id: field.id,
                cells: ({ bodyRowCellClassName }) => (
                  <Fragment>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell,
                        classes.dateColumn
                      )}
                    >
                      <div className={classes.moduleTypeContainer}>
                        {field.dateFormatted}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={field.depositFormatted}
                      >
                        {field.depositFormatted}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={field.returnsFormatted}
                      >
                        {field.returnsFormatted}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={field.totalFormatted}
                      >
                        {field.totalFormatted}
                      </div>
                    </td>
                  </Fragment>
                )
              };
            }, dataStore.resultsTableData)}
          />
        </div>
      )}
    </div>
  );
};

export default ResultsCard;
