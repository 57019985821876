import * as R from 'ramda';
import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Card from './card.js';
import { CardsListLayouts } from './shared.js';

const indexedMap = R.addIndex(R.map);

const Margin = 10;

const useStyles = createUseStyles({
  cardsContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    margin: `-${Margin}px`,

    '&$compact': {
      margin: '0'
    }
  },
  card: {
    margin: `${Margin}px`,

    '&$compact': {
      margin: '0'
    }
  },
  legendContainer: {
    marginTop: '20px',
    display: 'flex'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '28px'
  },
  legendColor: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    marginRight: '6px'
  },
  legendText: {
    // color: Colors.gray50,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx
  },
  compact: {}
});

export const CardsList = ({
  cards,
  selectedCardId,
  onCardSelected,
  cardLayout,
  legend,
  cardsContainerClassName,
  layout,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <div data-testid={restProps['data-testid']}>
      <div
        className={classNames(
          classes.cardsContainer,
          { [classes.compact]: layout === CardsListLayouts.compact },
          cardsContainerClassName
        )}
      >
        {R.map(
          c => (
            <Card
              key={c.id}
              cardsListLayout={layout}
              className={classNames(classes.card, {
                [classes.compact]: layout === CardsListLayouts.compact
              })}
              id={c.id}
              isSelected={c.id === selectedCardId}
              onSelect={() => {
                onCardSelected(c.id);
              }}
              imageSrc={c.imageSrc}
              imageStyle={c.imageStyle}
              title={c.title}
              layout={cardLayout}
              description={c.description}
            />
          ),
          cards
        )}
      </div>
      {!R.isNil(legend) && (
        <div
          className={classes.legendContainer}
          data-testid={'cards-list-legend'}
        >
          {indexedMap(
            (i, index) => (
              <div className={classes.legendItem} key={index}>
                <div
                  className={classes.legendColor}
                  style={{ backgroundColor: i.color }}
                />
                <div className={classes.legendText}>{i.text}</div>
              </div>
            ),
            legend
          )}
        </div>
      )}
    </div>
  );
};

CardsList.defaultProps = {
  layout: CardsListLayouts.default
};

export default CardsList;
