import React, { Fragment } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';

const styles = theme => {
  return {
    suffix: {
      display: 'inline-block',
      position: 'relative',

      '&::after': {
        content: '"%"',
        position: 'absolute',
        top: '8px',
        right: '20px'
      },

      '& $textInput': {
        paddingRight: '35px'
      }
    },

    textInput: {
      width: '140px',
      height: '35px',
      border: `2px solid ${theme.inputBorderColor}`,
      borderRadius: theme.inputBorderRadius,
      padding: '6px 20px',
      color: theme.inputTextColor,
      backgroundColor: theme.inputBackgroundColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'right',
      outline: 'none',
      '-moz-appearance': 'textfield',

      '&::-ms-clear': {
        display: 'none'
      },

      '&::placeholder': {
        color: Colors.gray50
      },

      '&:focus': {
        border: `2px solid ${theme.inputFocusedBorderColor}`,
        backgroundColor: theme.inputFocusedBackgroundColor
      },

      [`&::-webkit-outer-spin-button`]: {
        '-webkit-appearance': 'none',
        margin: 0
      },

      [`&::-webkit-inner-spin-button`]: {
        '-webkit-appearance': 'none',
        margin: 0
      },

      [`&:-webkit-autofill`]: {
        border: `2px solid ${theme.inputBorderColor}`,
        '-webkit-text-fill-color': theme.inputTextColor,
        '-webkit-box-shadow': `0 0 0px 1000px ${theme.inputBackgroundColor} inset`,
        transition: `background-color 5000s ease-in-out 0s`
      },

      [`&:-webkit-autofill:focus`]: {
        border: `2px solid ${theme.inputFocusedBorderColor}`,
        '-webkit-text-fill-color': theme.inputTextColor,
        '-webkit-box-shadow': `0 0 0px 1000px ${theme.inputFocusedBackgroundColor} inset`,
        transition: `background-color 5000s ease-in-out 0s`
      },

      '&:disabled': {
        backgroundColor: Colors.gray150,
        color: Colors.gray50,
        borderColor: theme.inputBorderColor
      },
      '&:read-only': {
        backgroundColor: Colors.gray150,
        color: Colors.gray50,
        borderColor: theme.inputBorderColor
      }
    },
    invalid: {
      border: `2px solid ${Colors.red50}`
    }
  };
};

const getSuffixClassName = (isSuffixShow, classes) => {
  if (isSuffixShow) {
    return classes.suffix;
  } else {
    return null;
  }
};

const BaseTextInput = ({
  isSuffixShow = false,
  type,
  className,
  classes,
  forwardedRef,
  isInvalid,
  config,
  ...rest
}) => {
  return (
    <Fragment>
      <div className={classNames(getSuffixClassName(isSuffixShow, classes))}>
        <input
          type={type}
          {...rest}
          className={classNames(
            classes.textInput,
            {
              [classes.invalid]: isInvalid
            },
            className
          )}
        />
      </div>
    </Fragment>
  );
};

export default injectSheet(styles)(BaseTextInput);
