import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty, isNil, mergeDeepWith, path } from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Highcharts from 'highcharts';

import { i18n } from 'i18n/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import ThemeProvider from 'features/shared/components/themeProvider/index.js';
import {
  getDefaultCustomerConfig,
  readCustomerConfig
} from '../utils/index.js';

export const CustomerConfigContext = React.createContext(null);

export const CustomerConfigProvider = ({ children }) => {
  const match = useRouteMatch(routeTemplates.home.config);
  const customerId = path(['params', 'customerId'], match);
  const langCode = path(['params', 'langCode'], match);
  const cultureCode = langCode;
  const [finalCustomerConfig, setFinalCustomerConfig] = useState(null);

  useEffect(() => {
    const initCustomerConfig = async () => {
      const customerConfig = await readCustomerConfig(
        customerId,
        cultureCode.split('-')[0]
      );

      const config = getDefaultCustomerConfig(customerId) || {};
      let translations = {};
      if (config?.translations) {
        translations = config?.translations[cultureCode] || {};
      }

      const defaultCustomerConfig = {
        ...config,
        translations,
        cultureCode
      };

      const mergedCustomerConfig = mergeDeepWith(
        (leftValue, rightValue) => {
          if (!isNil(rightValue) && !isEmpty(rightValue)) {
            return rightValue;
          }

          if (!isNil(leftValue) && !isEmpty(leftValue)) {
            return leftValue;
          }

          return rightValue;
        },
        defaultCustomerConfig,
        customerConfig
      );

      Highcharts.setOptions({
        lang: {
          shortMonths: [
            i18n('shared.shortMonths.jan', customerConfig.translations),
            i18n('shared.shortMonths.feb', customerConfig.translations),
            i18n('shared.shortMonths.mar', customerConfig.translations),
            i18n('shared.shortMonths.apr', customerConfig.translations),
            i18n('shared.shortMonths.may', customerConfig.translations),
            i18n('shared.shortMonths.jun', customerConfig.translations),
            i18n('shared.shortMonths.jul', customerConfig.translations),
            i18n('shared.shortMonths.aug', customerConfig.translations),
            i18n('shared.shortMonths.sep', customerConfig.translations),
            i18n('shared.shortMonths.oct', customerConfig.translations),
            i18n('shared.shortMonths.nov', customerConfig.translations),
            i18n('shared.shortMonths.dec', customerConfig.translations)
          ]
        }
      });

      setFinalCustomerConfig(mergedCustomerConfig);
    };

    initCustomerConfig();
  }, [cultureCode, customerId]);

  if (!finalCustomerConfig) {
    return null;
  }

  return (
    <Fragment>
      <Helmet link={finalCustomerConfig.styleSheets} />
      <CustomerConfigContext.Provider value={finalCustomerConfig}>
        <StylesProvider injectFirst>
          <ThemeProvider styles={finalCustomerConfig.styles}>
            {children}
          </ThemeProvider>
        </StylesProvider>
      </CustomerConfigContext.Provider>
    </Fragment>
  );
};
