export const TextFontSizes = {
  xxSmallPx: '9px',
  xxSmallLineHeightPx: '11.7px',

  xSmallPx: '12px',
  xSmallLineHeightPx: '14.54px',

  smallPx: '14px',
  smallLineHeightPx: '16.97px',

  mediumPx: '16px',
  mediumLineHeightPx: '19.39px',

  largePx: '24px',
  largeLineHeightPx: '29.09px',

  xLargePx: '40px',
  xLargeLineHeightPx: '48.48px',

  xxLargePx: '60px',
  xxLargeLineHeightPx: '72.72px'
};

export const ButtonFontSizes = {
  medium: '16px',
  large: '16px'
};

// https://github.com/jonathantneal/css-font-weight-names
export const FontWeights = {
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700'
};

export const FontFamilies = {
  roboto: "'Roboto', sans-serif"
};
