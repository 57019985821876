import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

import PoweredBy from 'features/shared/components/mainLayout/poweredBy.js';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import SettingsCard from './settingsCard.js';
import ReturnChartCard from './returnChartCard.js';
import ResultsCard from './resultsCard.js';

const Margin = 10;

const useStyles = createUseStyles({
  pageContainer: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'row',

    '& > div:first-child': {
      marginRight: `${Margin}px`
    },
    '& > div:last-child': {
      marginLeft: `${Margin}px`
    }
  },
  column: {
    width: `calc(50% - ${Margin}px)`,
    alignSelf: 'stretch'
  },
  firstColumn: {
    alignSelf: 'flex-start',
    '& > div:first-child': {
      marginBottom: '20px'
    }
  },
  poweredByLabel: {
    marginTop: '5px'
  }
});

const Calculator = () => {
  const classes = useStyles();
  const leftRef = React.useRef();
  const [leftHeight, setLeftHeight] = React.useState(null);

  const onResize = () => {
    const leftCardHeight = leftRef.current.clientHeight;
    setLeftHeight(leftCardHeight);
  };

  return (
    <MainLayout pageContainer={classes.pageContainer}>
      <div className={classes.wrapper}>
        <div
          ref={leftRef}
          className={classNames(classes.column, classes.firstColumn)}
        >
          <SettingsCard />
          <ReturnChartCard />
          <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </div>
        <div className={classes.column}>
          <ResultsCard leftHeight={leftHeight} />
        </div>
      </div>
      <div className={classes.poweredByLabel}>
        <PoweredBy />
      </div>
    </MainLayout>
  );
};

export default Calculator;
