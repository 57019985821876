import * as R from 'ramda';

const routeTemplatesInternal = [
  {
    id: 'home',
    config: '/:customerId?/:langCode?',
    build: (customerId, langCode) => `/${customerId}/${langCode}`,
    children: [
      {
        id: 'calculatorUc1',
        config: '/:customerId/:langCode/calculator',
        build: (customerId, langCode) =>
          `/${customerId}/${langCode}/calculator`,
        title: 'Saving Calculator'
      },
      {
        id: 'calculatorUc1Test',
        config: '/:customerId/:langCode/calculator-test',
        build: (customerId, langCode) =>
          `/${customerId}/${langCode}/calculator-test`,
        title: 'Saving Calculator'
      },
      {
        id: 'calculatorUc6',
        config: '/:customerId/:langCode/calculator-uc-6',
        build: (customerId, langCode) =>
          `/${customerId}/${langCode}/calculator-uc-6`,
        title: 'Saving Calculator'
      },
      {
        id: 'calculatorUc6Test',
        config: '/:customerId/:langCode/calculator-uc-6-test',
        build: (customerId, langCode) =>
          `/${customerId}/${langCode}/calculator-uc-6-test`,
        title: 'Saving Calculator'
      }
    ]
  }
];

const initRouteTemplateTitles = R.curry((defaultTitle, routeTemplates) => {
  return R.map(routeTemplate => {
    const title = routeTemplate.title || defaultTitle;
    return {
      ...routeTemplate,
      title,
      children: R.isNil(routeTemplate.children)
        ? routeTemplate.children
        : initRouteTemplateTitles(title, routeTemplate.children)
    };
  }, routeTemplates);
});

const flattenRouteTemplates = routeTemplates => {
  let flattenedRouteTemplates = [];

  routeTemplates.forEach(rt => {
    flattenedRouteTemplates.push(rt);
    if (rt.children != null) {
      flattenedRouteTemplates = flattenedRouteTemplates.concat(
        flattenRouteTemplates(rt.children)
      );
    }
  });

  return flattenedRouteTemplates;
};

const reduceRouteTemplates = routeTemplates => {
  return R.reduce(
    (reducedRouteTemplates, rt) => {
      return {
        ...reducedRouteTemplates,
        [rt.id]: {
          ...rt,
          children:
            rt.children != null ? flattenRouteTemplates(rt.children) : []
        }
      };
    },
    {},
    routeTemplates
  );
};

export const routeTemplates = initRouteTemplateTitles(
  'title.deepAlpha',
  routeTemplatesInternal
);

export default R.pipe(
  flattenRouteTemplates,
  reduceRouteTemplates
)(routeTemplates);
