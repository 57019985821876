import * as R from 'ramda';
import axios from 'axios';

import {
  getQAuthAccessToken,
  getCustomerConfig
} from 'features/shared/api/index.js';
import sun_lounger from './assets/icons/sun_lounger.svg';
import bike from './assets/icons/bike.svg';
import zipline from './assets/icons/zipline.svg';
import translationsEnGbShared from './translations/en-gb.js';
import translationsNoNoShared from './translations/no-no.js';
import translationsSvSeShared from './translations/sv-se.js';

const ICONS = {
  sun_lounger: sun_lounger,
  bike: bike,
  zipline: zipline
};

export const mapImageSrcToIcon = src => ICONS?.[src] || src;

const RiskProfilesShared = [
  {
    id: 'low',
    imageSrc: 'sun_lounger',
    title: 'riskProfile.lowRisk.title',
    description: ['riskProfile.lowRisk.description_1'],
    interest_rate: 0.059,
    annual_std: 0.08
  },
  {
    id: 'medium',
    imageSrc: 'bike',
    imageStyle: { marginBottom: '1px' },
    title: 'riskProfile.mediumRisk.title',
    description: ['riskProfile.mediumRisk.description_1'],
    interest_rate: 0.068,
    annual_std: 0.12
  },
  {
    id: 'high',
    imageSrc: 'zipline',
    title: 'riskProfile.highRisk.title',
    description: ['riskProfile.highRisk.description_1'],
    interest_rate: 0.076,
    annual_std: 0.16
  }
];

const DefaultRiskProfileIdShared = 'medium';

export const readCustomerConfig = async (customerId, language) => {
  const cancelSource = axios.CancelToken.source();

  try {
    const accessToken = await getQAuthAccessToken(
      customerId,
      cancelSource.token
    );

    const response = await getCustomerConfig(
      accessToken,
      customerId,
      language,
      cancelSource.token
    );

    const customerConfigData = response.data;
    const settingsKey = customerConfigData.customer.settings['qsc-front'];

    const customerConfig = R.pipe(
      R.set(R.lensProp('language'), customerConfigData.customer.language)
    )(settingsKey);

    return customerConfig;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }

  return {};
};

export const getDefaultCustomerConfig = customerId => {
  const quantfolioConfig = {
    id: 'quantfolio',
    styles: {
      fontFamily: "'Roboto', sans-serif",
      tabularNumsFontFamily: "'Roboto', sans-serif",

      backgroundColor: '#ffffff',
      textColor: '#28363D',
      cardBackgroundColor: '#ECEFF1',
      primaryColor: '#F6922E',

      sliderRootColor: '#B0BEC5',
      sliderThumbColor: '#F6922E',
      sliderThumbFocusedColor: '#DB7714',
      sliderTrackColor: '#F6922E',

      inputBackgroundColor: '#ffffff',
      inputFocusedBackgroundColor: '#ffffff',
      inputBorderColor: '#B0BEC5',
      inputFocusedBorderColor: '#F6922E',
      inputBorderRadius: 6,
      inputTextColor: '#28363D',

      returnChartDarkColor: '#F6922E',
      returnChartLightColor: '#F2B77C',
      returnChartLabelsColor: '#78909C',
      returnChartTooltipBackgroundColor: '#FFFFFF',
      returnChartTooltipBorderColor: '#F6922E',
      returnChartTooltipHeaderColor: '#78909C',
      returnChartTooltipFirstLineColor: '#28363D',
      returnChartTooltipLastLineColor: '#F6922E',
      returnChartExpectedReturnColor: '#F6922E',
      returnChartBoundsColor: '#F2B77C',

      tableHeaderBackgroundColor: '#CFD8DC',
      tableHeaderColor: '#6A8595',
      tableEvenRowCellColor: '#ECEFF1',
      tableOddRowCellColor: '#DDE4E8',
      tableHoverColor: 'rgba(246, 146, 46, 0.4)',

      cardsListCardBackgroundColor: '#ffffff',
      cardsListCardCheckMarkBackgroundColor: '#F6922E'
    },
    styleSheets: [
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'
      }
    ],
    translations: {
      'no-no': translationsNoNoShared
    },
    riskProfiles: RiskProfilesShared,
    defaultRiskProfileId: DefaultRiskProfileIdShared
  };

  const deepAlphaConfig = {
    id: 'deepAlpha',
    styles: {
      fontFamily: "'Roboto', sans-serif",
      tabularNumsFontFamily: "'Roboto', sans-serif",

      backgroundColor: '#1A242A',
      textColor: '#ffffff',
      cardBackgroundColor: '#28363D',
      primaryColor: '#0BB3F3',

      sliderRootColor: '#3C4A54',
      sliderThumbColor: '#0BB3F3',
      sliderThumbFocusedColor: '#04739E',
      sliderTrackColor: '#0BB3F3',

      inputBackgroundColor: '#1e2a30',
      inputFocusedBackgroundColor: '#164152',
      inputBorderColor: '#3c4a54',
      inputFocusedBorderColor: '#0bb3f3',
      inputBorderRadius: 35,
      inputTextColor: '#FFFFFF',

      returnChartDarkColor: '#2D6E3D',
      returnChartLightColor: '#41B851',
      returnChartLabelsColor: '#78909C',
      returnChartTooltipBackgroundColor: '#164152',
      returnChartTooltipBorderColor: '#0BB3F3',
      returnChartTooltipHeaderColor: '#78909C',
      returnChartTooltipFirstLineColor: '#ffffff',
      returnChartTooltipLastLineColor: '#0BB3F3',
      returnChartExpectedReturnColor: '#219653',
      returnChartBoundsColor: 'rgba(39, 174, 96, 0.4)',

      tableHeaderBackgroundColor: '#3C4A54',
      tableHeaderColor: '#6A8595',
      tableEvenRowCellColor: '#28363D',
      tableOddRowCellColor: '#3C4A54',
      tableHoverColor: '#04739E',

      cardsListCardBackgroundColor: '#3C4A54',
      cardsListCardCheckMarkBackgroundColor: '#0BB3F3'
    },
    styleSheets: [
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'
      }
    ],
    translations: {
      'no-no': translationsNoNoShared
    },
    riskProfiles: RiskProfilesShared,
    defaultRiskProfileId: DefaultRiskProfileIdShared
  };

  const connectumConfig = {
    id: 'connectum',
    styles: {
      fontFamily: "'Libre Franklin', sans-serif",
      tabularNumsFontFamily: "'Open Sans', sans-serif",

      backgroundColor: '#ffffff',
      textColor: '#383838',
      cardBackgroundColor: '#f0f0f0',
      primaryColor: '#32374B',

      sliderRootColor: '#c0ccd2',
      sliderThumbColor: '#32374B',
      sliderThumbFocusedColor: '#32374B',
      sliderTrackColor: '#32374B',

      inputBackgroundColor: '#ffffff',
      inputFocusedBackgroundColor: '#ffffff',
      inputBorderColor: '#A5A5A5',
      inputFocusedBorderColor: '#32374B',
      inputBorderRadius: 4,
      inputTextColor: '#32374B',

      returnChartDarkColor: '#32374B',
      returnChartLightColor: 'rgba(50, 55, 75, 0.3)',
      returnChartLabelsColor: '#78909C',
      returnChartTooltipBackgroundColor: '#FFFFFF',
      returnChartTooltipBorderColor: '#32374B',
      returnChartTooltipHeaderColor: '#78909C',
      returnChartTooltipFirstLineColor: '#383838',
      returnChartTooltipLastLineColor: '#32374B',
      returnChartExpectedReturnColor: '#32374B',
      returnChartBoundsColor: 'rgba(50, 55, 75, 0.3)',

      tableHeaderBackgroundColor: '#D7D7D7',
      tableHeaderColor: '#263238',
      tableEvenRowCellColor: '#f0f0f0',
      tableOddRowCellColor: '#D7D7D7',
      tableHoverColor: 'rgba(50, 55, 75, 0.4)',

      dataListSeparatorColor: '#dadada',
      dataListTitleColor: '#263238',
      dataListHeaderColor: '#828282',

      cardsListCardBackgroundColor: '#ffffff',
      cardsListCardCheckMarkBackgroundColor: '#32374B'
    },
    styleSheets: [
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'
      },
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap'
      }
    ],
    translations: {
      'en-gb': {
        ...translationsEnGbShared,

        'settingsCard.title': 'Investor Calculator',
        'settingsCard.howMuchSave':
          'How much do you intend to add to the portfolio per month?',
        'settingsCard.doYouHaveSavingsToInvest':
          'How much do you intend to invest initially?',
        'settingsCard.howManyYearsDoYouWantSave':
          'For how many years do you intend to add to the portfolio?',
        'settingsCard.whichRiskProfileWouldYouPrefer':
          'Which risk profile would you prefer?',

        'returnChartCard.title': 'Returns Chart',
        'returnChartCard.description':
          'The chart shows how your investment portfolio could evolve compared to placing the money on deposit in a bank account',
        'returnChartCard.expectedReturn': 'Expected Return',
        'returnChartCard.upperAndLowerBound':
          'Optimistic & pessimistic scenario',

        'resultsCard.expectedResult': 'In {0} years your expected result is:',
        'resultsCard.showDetails': 'Show details',
        'resultsCard.hideDetails': 'Hide details',
        'resultsCard.table.header': 'Investment plan details',
        'resultsCard.table.header.year': 'Year',
        'resultsCard.table.header.expectedReturn': 'Expected return',
        'resultsCard.table.header.upperBound': 'Optimistic',
        'resultsCard.table.header.lowerBound': 'Pessimistic',

        'riskProfile.lowRisk.title': 'Low Risk',
        'riskProfile.lowRisk.description_1':
          'Accept a lower return, want minor portfolio fluctuations',
        'riskProfile.mediumRisk.title': 'Moderate Risk',
        'riskProfile.mediumRisk.description_1':
          'Hope for moderate return, accept portfolio fluctuations',
        'riskProfile.highRisk.title': 'High Risk',
        'riskProfile.highRisk.description_1':
          'Hope for high return, accept large portfolio fluctuations'
      },
      'no-no': {
        ...translationsNoNoShared,

        'settingsCard.title': 'Investorkalkulator',
        'settingsCard.howMuchSave':
          'Hvor mye planlegger du å tilføre til porteføljen per måned?',
        'settingsCard.doYouHaveSavingsToInvest':
          'Hvor mye kan investeres som engangsbeløp?',
        'settingsCard.howManyYearsDoYouWantSave':
          'I hvor mange år kan du tilføre midler til porteføljen?',
        'settingsCard.whichRiskProfileWouldYouPrefer':
          'Hvilken risikoprofil foretrekker du?',

        'returnChartCard.title': 'Avkastningsprognose',
        'returnChartCard.description':
          'Diagrammet viser hvordan porteføljen forventes å utvikle seg sammenlignet med å ha penger på bankkonto',
        'returnChartCard.expectedReturn': 'Forventet avkastning',
        'returnChartCard.upperAndLowerBound':
          'Optimistisk & pessimistisk utfall',

        'resultsCard.expectedResult': 'Om {0} år er ditt forventede resultat:',
        'resultsCard.showDetails': 'Vis detaljer',
        'resultsCard.hideDetails': 'Skjul detaljer',
        'resultsCard.table.header': 'Investeringsplan, detaljer',
        'resultsCard.table.header.year': 'År',
        'resultsCard.table.header.expectedReturn': 'Forventet avkastning',
        'resultsCard.table.header.upperBound': 'Optimistisk',
        'resultsCard.table.header.lowerBound': 'Pessimistisk',

        'riskProfile.lowRisk.title': 'Lav risiko',
        'riskProfile.lowRisk.description_1':
          'Aksepterer lav avkastning, ønsker små verdisvingninger',
        'riskProfile.mediumRisk.title': 'Moderat risiko',
        'riskProfile.mediumRisk.description_1':
          'Håper på moderat avkastning, inneforstått med verdisvingninger',
        'riskProfile.highRisk.title': 'Høy risiko',
        'riskProfile.highRisk.description_1':
          'Håper på høy avkastning, inneforstått med store svingninger'
      },
      'sv-se': {
        ...translationsSvSeShared,

        'settingsCard.title': 'Investerarkalkylator',
        'settingsCard.howMuchSave':
          'Hur mycket planerar du att tillföra till portföljen per månad?',
        'settingsCard.doYouHaveSavingsToInvest':
          'Hur mycket planerar du att investera initialt?',
        'settingsCard.howManyYearsDoYouWantSave':
          'Under hur många år kommer du att kunna tillföra kapital?',
        'settingsCard.whichRiskProfileWouldYouPrefer':
          'Vilken riskprofil föredrar du?',

        'returnChartCard.title': 'Avkastningsprognos',
        'returnChartCard.description':
          'Diagrammet visar hur vi uppskattar att din portfölj kan utvecklas jämfört med om kapitalet placeras på bankkonto',
        'returnChartCard.expectedReturn': 'Förväntad avkastning',
        'returnChartCard.upperAndLowerBound':
          'Optimistiskt & pessimistiskt utfall',

        'resultsCard.expectedResult': 'Om {0} år är ditt förväntade resultat:',
        'resultsCard.showDetails': 'Visa detaljer',
        'resultsCard.hideDetails': 'Gömma detaljer',
        'resultsCard.table.header': 'Investeringsplan, detaljer',
        'resultsCard.table.header.year': 'År',
        'resultsCard.table.header.expectedReturn': 'Förväntad avkastning',
        'resultsCard.table.header.upperBound': 'Optimistiskt',
        'resultsCard.table.header.lowerBound': 'Pessimistiskt',

        'riskProfile.lowRisk.title': 'Låg risk',
        'riskProfile.lowRisk.description_1':
          'Accepterar låg avkastning, önskar små värdesvängningar',
        'riskProfile.mediumRisk.title': 'Medelhög risk',
        'riskProfile.mediumRisk.description_1':
          'Hoppas på medelhög avkastning, accepterar värdesvängningar',
        'riskProfile.highRisk.title': 'Hög risk',
        'riskProfile.highRisk.description_1':
          'Hoppas på hög avkastning, accepterar stora värdesvängningar'
      }
    },
    riskProfiles: RiskProfilesShared,
    defaultRiskProfileId: DefaultRiskProfileIdShared
  };

  const configs = {
    quantfolio: quantfolioConfig,
    deepAlpha: deepAlphaConfig,
    connectum: connectumConfig
  };

  return configs[customerId];
};
