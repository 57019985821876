import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';

const Unchecked = ({ className, color = Colors.gray50 } = {}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M18 2v14c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h14c1.1 0 2 .9 2 2zm-2 0H2v14h14V2" // TODO: manualy recalculate values and change to fit 0 0 20 20
          />
        </g>
      </g>
    </svg>
  );
};

const Checked = ({ className, color = Colors.blue150 } = {}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
    >
      <g>
        <g>
          <path
            fill={color}
            d="M7 14L2 9l1.41-1.41L7 11.17l7.59-7.59L16 5zm9-14H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" // TODO: manualy recalculate values and change to fit 0 0 20 20
          />
        </g>
      </g>
    </svg>
  );
};

const styles = {
  container: {
    position: 'relative',
    lineHeight: '0'
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    cursor: 'pointer',
    width: '1.6rem',
    height: '1.6rem'
  },
  checked: {
    userSelect: 'none'
  },
  unchecked: {
    userSelect: 'none'
  }
};

const Checkbox = ({
  checked,
  onChange,
  onClick,
  className,
  classes,
  checkedColor,
  uncheckedColor
}) => {
  return (
    <div className={classNames(classes.container, className)}>
      {checked && <Checked className={classes.checked} color={checkedColor} />}
      {!checked && (
        <Unchecked className={classes.unchecked} color={uncheckedColor} />
      )}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        className={classes.input}
      />
    </div>
  );
};

export default injectSheet(styles)(Checkbox);
