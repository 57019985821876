export default {
  'shared.currency': '€',
  'shared.shortMonths.jan': 'Jan',
  'shared.shortMonths.feb': 'Feb',
  'shared.shortMonths.mar': 'Mar',
  'shared.shortMonths.apr': 'Apr',
  'shared.shortMonths.may': 'May',
  'shared.shortMonths.jun': 'Jun',
  'shared.shortMonths.jul': 'Jul',
  'shared.shortMonths.aug': 'Aug',
  'shared.shortMonths.sep': 'Sep',
  'shared.shortMonths.oct': 'Oct',
  'shared.shortMonths.nov': 'Nov',
  'shared.shortMonths.dec': 'Dec',
  'shared.poweredBy': 'Powered by',

  'settingsCard.title': 'Saving Calculator',
  'settingsCard.howMuchSave': 'How much do you want to save each month?',
  'settingsCard.doYouHaveSavingsToInvest':
    'Do you have savings you want to invest when starting you saving plan?',
  'settingsCard.whatIsExpectedYearlyReturn':
    'What is the expected yearly return on your investment?',
  'settingsCard.howManyYearsDoYouWantSave':
    'How many years do you want to save?',
  'settingsCard.whichRiskProfileWouldYouPrefer':
    'Which risk profile would you prefer?',

  'returnChartCard.title': 'Returns Chart',
  'returnChartCard.description':
    'The chart shows how your saving plan will evolve compared to putting money in a bank account.',
  'returnChartCard.yourSavingPlan': 'Your saving plan',
  'returnChartCard.savingInYourBankAccount': 'Saving in your bank account',
  'returnChartCard.expectedReturn': 'Expected return',
  'returnChartCard.upperAndLowerBound': 'Upper & lower bound',

  'resultsCard.expectedResult': 'In {0} years your expected result is:',
  'resultsCard.showDetails': 'Show details',
  'resultsCard.hideDetails': 'Hide details',
  'resultsCard.table.header': 'Saving plan details',
  'resultsCard.table.header.year': 'Year',
  'resultsCard.table.header.date': 'Date',
  'resultsCard.table.header.accumulatedDeposits': 'Accumulated Deposits',
  'resultsCard.table.header.accumulatedReturns': 'Accumulated Returns',
  'resultsCard.table.header.totalValue': 'Total Value',
  'resultsCard.table.header.expectedReturn': 'Expected return',
  'resultsCard.table.header.upperBound': 'Upper bound',
  'resultsCard.table.header.lowerBound': 'Lower bound',

  'riskProfile.lowRisk.title': 'Low risk',
  'riskProfile.lowRisk.description_1':
    'Accept lower returns, want minor fluctuations',
  'riskProfile.mediumRisk.title': 'Medium risk',
  'riskProfile.mediumRisk.description_1':
    'Hope for moderate returns, accept fluctuations',
  'riskProfile.highRisk.title': 'High risk',
  'riskProfile.highRisk.description_1':
    'Hope for high returns, accept large fluctuations'
};
