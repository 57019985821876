import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { formatNumber } from 'features/shared/utils/number.js';

HighchartsMore(Highcharts);

const styles = theme => {
  return {
    highcharts: {
      flex: '1 1 auto',
      width: '100%',
      height: '300px',
      '& svg': {
        width: '100%'
      }
    },
    headerFormat: {
      fontFamily: theme.tabularNumsFontFamily,
      fontSize: TextFontSizes.xSmallPx,
      lineHeight: TextFontSizes.xSmallLineHeightPx,
      color: theme.returnChartTooltipHeaderColor
    },
    tooltipFirstLine: {
      fontFamily: theme.tabularNumsFontFamily,
      fontSize: TextFontSizes.xSmallPx,
      lineHeight: TextFontSizes.xSmallLineHeightPx,
      color: theme.returnChartTooltipFirstLineColor,
      display: 'inline-block',
      marginTop: '10px',
      marginBottom: '5px'
    },
    tooltipLastLine: {
      fontFamily: theme.tabularNumsFontFamily,
      fontSize: TextFontSizes.xSmallPx,
      lineHeight: TextFontSizes.xSmallLineHeightPx,
      color: theme.returnChartTooltipLastLineColor
    }
  };
};

const getOptions = theme => ({
  credits: {
    enabled: false
  },
  chart: {
    backgroundColor: 'transparent',
    marginTop: 20,
    marginRight: 0
  },
  title: {
    text: null
  },
  xAxis: {
    tickmarkPlacement: 'on',
    crosshair: {
      color: '#ffffff',
      zIndex: 5
    },
    lineWidth: 0,
    tickLength: 5,
    tickColor: '#CFD8DC',
    type: 'datetime',
    labels: {
      format: '{value:‘%y}',
      style: {
        color: theme.returnChartLabelsColor,
        fontSize: TextFontSizes.xSmallPx,
        lineHeight: TextFontSizes.xSmallLineHeightPx,
        fontFamily: theme.tabularNumsFontFamily
      }
    }
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      enabled: false
    },
    labels: {
      style: {
        color: theme.returnChartLabelsColor,
        fontSize: TextFontSizes.xSmallPx,
        lineHeight: TextFontSizes.xSmallLineHeightPx,
        fontFamily: theme.tabularNumsFontFamily
      }
    }
  },
  tooltip: {
    crosshairs: true,
    shared: true,
    xDateFormat: '%b %Y',
    useHTML: true
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      }
    }
  },
  legend: {
    enabled: false
  },
  series: []
});

const getHeaderFormat = classes => {
  return `<span class="${classes.headerFormat}">{point.key}</span><br>`;
};

const ReturnChart = ({ cultureCode, data, styles, classes }) => {
  const chartComponent = useRef();

  useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    reflow();
    window.addEventListener('resize', reflow);
    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const options = getOptions(styles);
  const plotOptions = R.pipe(
    R.set(R.lensPath(['series']), data),
    R.set(R.lensPath(['tooltip', 'headerFormat']), getHeaderFormat(classes)),
    R.set(R.lensPath(['tooltip', 'pointFormatter']), function() {
      const value = this.series.options.custom.isMean
        ? formatNumber(cultureCode, this.y, 0, 0)
        : `${formatNumber(cultureCode, this.low, 0, 0)} - ${formatNumber(
            cultureCode,
            this.high,
            0,
            0
          )}`;

      return `<span class="${classes.tooltipFirstLine}">${this.series.options.custom.type}</span><br><span class="${classes.tooltipLastLine}">${value}</span><br>`;
    }),
    R.set(
      R.lensPath(['tooltip', 'backgroundColor']),
      styles.returnChartTooltipBackgroundColor
    ),
    R.set(
      R.lensPath(['tooltip', 'borderColor']),
      styles.returnChartTooltipBorderColor
    )
  )(options);

  return (
    <div className={classes.highcharts}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { width: 'auto', height: '300px' } }}
        options={plotOptions}
      />
    </div>
  );
};

export default injectSheet(styles)(ReturnChart);
