import React from 'react';
import { createUseStyles } from 'react-jss';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { i18n } from 'i18n/index.js';
import { getUc6SavingCalculations } from 'features/shared/api/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import Slider from 'features/shared/components/slider/index.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { getNumberInputFormat } from 'features/shared/utils/number.js';
import BaseTextInput from 'features/shared/components/input/baseTextInput.js';
import CardsList from 'features/sharedModules/cardsList/components/cardsList.js';
import { CardsListLayouts } from 'features/sharedModules/cardsList/components/shared.js';
import {
  mapServerDataToTable,
  mapServerDataToExpectedReturnChart,
  mapServerDataToBoundsChart
} from '../services/mapping.js';
import { inputConfig, sliderConfig } from '../constants.js';
import { useDataStore } from '../services/dataStore.js';
import { useFormStore } from '../services/formStore.js';
import { mapImageSrcToIcon } from 'features/sharedModules/customerConfig/utils/index';

const indexedMap = R.addIndex(R.map);

const useNumberInputStyles = createUseStyles(theme => ({
  root: {
    fontFamily: theme.tabularNumsFontFamily
  }
}));

const NumberInput = ({ className, ...restProps }) => {
  const classes = useNumberInputStyles();

  return (
    <BaseTextInput
      className={classNames(classes.root, className)}
      {...restProps}
    />
  );
};

const useStyles = createUseStyles(theme => ({
  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '20px'
  },
  text: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    marginBottom: '7px'
  },
  cardListText: {
    marginBottom: '17px'
  },
  mobileCardsContainer: {
    marginLeft: '-20px',
    marginRight: '-20px'
  },
  segment: {
    paddingBottom: '20px'
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  slider: {
    flex: '1 1 auto',
    maxWidth: '550px'
  }
}));

const isSavingValid = value =>
  !R.isNil(value) && value >= inputConfig.monthlySaving.min;

const isHoldingValid = value =>
  !R.isNil(value) && value >= inputConfig.oneTimeSaving.min;

const isHorizonValid = value =>
  !R.isNil(value) && value >= inputConfig.horizon.min;

const isRiskProfileValid = value => !R.isNil(value);

const SettingsCard = ({ isMobile }) => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const { thousandSeparator, decimalSeparator } = getNumberInputFormat(
    customerConfig.cultureCode
  );
  const { customerId } = useParams();
  const dataStore = useDataStore();
  const formStore = useFormStore();
  const { saving, holding, horizon, riskProfile } = formStore;
  const cards = React.useMemo(
    () =>
      R.map(
        p => ({
          id: p.id,
          imageSrc: mapImageSrcToIcon(p.imageSrc),
          imageStyle: p.imageStyle,
          title: i18n(p.title, customerConfig.translations),
          description: (
            <div>
              {indexedMap(
                (d, index) => (
                  <div key={index}>{i18n(d, customerConfig.translations)}</div>
                ),
                p.description
              )}
            </div>
          )
        }),
        customerConfig.riskProfiles
      ),
    [customerConfig.riskProfiles]
  );

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const timeout = setTimeout(async () => {
      const { saving, holding, horizon, riskProfile } = useFormStore.getState();

      if (
        !isSavingValid(saving) ||
        !isHoldingValid(holding) ||
        !isHorizonValid(horizon) ||
        !isRiskProfileValid(riskProfile)
      ) {
        return;
      }

      const { interest_rate, annual_std } = R.find(
        p => p.id === riskProfile,
        customerConfig.riskProfiles
      );
      const data = {
        percentiles: [0.025, 0.5, 0.975],
        saving: saving,
        holding: holding,
        horizon_in_years: horizon,
        interest_rate,
        annual_std
      };

      try {
        const accessToken = await getQAuthAccessToken(
          customerId,
          cancelSource.token
        );

        const response = await getUc6SavingCalculations(
          data,
          accessToken,
          cancelSource.token
        );
        const currentDate = new Date();
        const currentDateData = {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth()
        };

        dataStore.setData({
          tableData: mapServerDataToTable(currentDateData, response.data),
          expectedReturnChartData: mapServerDataToExpectedReturnChart(
            currentDateData,
            response.data
          ),
          boundsChartData: mapServerDataToBoundsChart(
            currentDateData,
            response.data
          ),
          horizon
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    }, 300);

    return () => {
      cancelSource.cancel();
      clearTimeout(timeout);
    };
  }, [saving, holding, horizon, riskProfile]);

  return (
    <div>
      <div className={classes.headerText}>
        {i18n('settingsCard.title', customerConfig.translations)}
      </div>
      <div className={classes.segment}>
        <div className={classes.text}>
          {i18n(
            'settingsCard.doYouHaveSavingsToInvest',
            customerConfig.translations
          )}
        </div>
        <div className={classes.inputs}>
          <Slider
            className={classes.slider}
            value={holding || 0}
            config={sliderConfig.oneTimeSaving}
            callbackFn={val => {
              formStore.changeHolding(val);
            }}
          />
          <NumberFormat
            customInput={NumberInput}
            value={holding}
            onValueChange={values => {
              formStore.changeHolding(values.floatValue);
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            allowedDecimalSeparators={[',', '.']}
            decimalScale={3}
            allowNegative={false}
            isInvalid={!isHoldingValid(holding)}
          />
        </div>
      </div>
      <div className={classes.segment}>
        <div className={classes.text}>
          {i18n('settingsCard.howMuchSave', customerConfig.translations)}
        </div>
        <div className={classes.inputs}>
          <Slider
            className={classes.slider}
            value={saving || 0}
            config={sliderConfig.monthlySaving}
            callbackFn={val => {
              formStore.changeSaving(val);
            }}
          />
          <NumberFormat
            customInput={NumberInput}
            value={saving}
            onValueChange={values => {
              formStore.changeSaving(values.floatValue);
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            allowedDecimalSeparators={[',', '.']}
            decimalScale={3}
            allowNegative={false}
            isInvalid={!isSavingValid(saving)}
          />
        </div>
      </div>
      <div className={classes.segment}>
        <div className={classes.text}>
          {i18n(
            'settingsCard.howManyYearsDoYouWantSave',
            customerConfig.translations
          )}
        </div>
        <div className={classes.inputs}>
          <Slider
            className={classes.slider}
            value={horizon || 0}
            config={sliderConfig.horizon}
            callbackFn={val => {
              formStore.changeHorizon(val);
            }}
          />
          <NumberFormat
            customInput={NumberInput}
            value={horizon}
            onValueChange={values => {
              formStore.changeHorizon(values.floatValue);
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            allowedDecimalSeparators={[',', '.']}
            decimalScale={0}
            allowNegative={false}
            isInvalid={!isHorizonValid(horizon)}
          />
        </div>
      </div>
      <div>
        <div className={classNames(classes.text, classes.cardListText)}>
          {i18n(
            'settingsCard.whichRiskProfileWouldYouPrefer',
            customerConfig.translations
          )}
        </div>
        <div
          className={classNames({ [classes.mobileCardsContainer]: isMobile })}
        >
          <CardsList
            selectedCardId={riskProfile}
            onCardSelected={id => {
              formStore.changeRiskProfile(id);
            }}
            cards={cards}
            layout={
              isMobile ? CardsListLayouts.compact : CardsListLayouts.default
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsCard;
