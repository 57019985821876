import React, { useReducer } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import { FontFamilies, FontWeights } from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index.js';

export const actionTypes = {
  TOGGLE_INPUT_FOCUS: 'TOGGLE_INPUT_FOCUS'
};

const initialState = {
  isInputFocused: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_INPUT_FOCUS: {
      return R.assoc('isInputFocused', action.payload, state);
    }
    default:
      return state;
  }
};

const styles = theme => ({
  inputContainer: {
    position: 'relative'
  },
  input: {
    width: '100%',
    height: 'calc(4rem + 4px)',
    border: `2px solid ${Colors.blue350}`,
    borderRadius: '8rem',
    padding: '0.8rem 6.7rem 0.8rem 2.4rem',
    color: Colors.white,
    backgroundColor: Colors.blue75,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
    display: 'inline-flex',

    '&:focus': {
      border: `2px solid ${Colors.blue150}`,
      backgroundColor: Colors.blue25
    }
  },
  invalid: {
    border: `2px solid ${Colors.red50}`
  },
  rightBlock: {
    position: 'absolute',
    top: 'calc(0.8rem + 2px)',
    right: '2.4rem',
    zIndex: 1,
    pointerEvents: 'none'
  },
  icon: {
    cursor: 'pointer',
    color: Colors.gray50,
    fontSize: '2.2rem',
    lineHeight: '2.4rem',

    '&$focused': {
      color: Colors.blue150
    }
  },
  focused: {},
  placeholder: {
    color: Colors.gray50
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const Input = ({
  forwardedRef,
  selectedOption,
  placeholder,
  onClick,
  isDropdownMenuOpen,
  onFocus,
  onBlur,
  className,
  iconClassName,
  isInvalid,
  classes
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className={classes.inputContainer} ref={forwardedRef}>
      <div
        className={classNames(
          classes.input,
          { [classes.invalid]: isInvalid },
          className
        )}
        tabIndex="0"
        onClick={onClick}
        onFocus={e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: true
          });

          onFocus && onFocus(e);
        }}
        onBlur={e => {
          dispatch({
            type: actionTypes.TOGGLE_INPUT_FOCUS,
            payload: false
          });

          onBlur && onBlur(e);
        }}
      >
        {R.isNil(selectedOption) ? (
          <div className={classNames(classes.content, classes.placeholder)}>
            {placeholder}
          </div>
        ) : (
          <div className={classes.content}>{selectedOption.title}</div>
        )}
      </div>
      <div className={classes.rightBlock}>
        <Icon
          className={classNames(
            classes.icon,
            {
              [classes.focused]: state.isInputFocused
            },
            iconClassName
          )}
          type={
            isDropdownMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }
        />
      </div>
    </div>
  );
};

export default injectSheet(styles)(Input);
