import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useStyles = createUseStyles(theme => ({
  circle: {
    fill: theme.cardsListCardCheckMarkBackgroundColor
  }
}));

const CheckMark = ({ className, ...restProps }) => {
  const classes = useStyles();

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={restProps['data-testid']}
    >
      <circle cx="20" cy="20" r="20" className={classes.circle} />
      <path d="M15 20L19 24L27 16" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default CheckMark;
