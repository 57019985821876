import * as R from 'ramda';
import moment from 'moment';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';

export const mapReturnsCharts = (data, translations) => {
  const currency = i18n('shared.currency', translations);

  return R.map(item => {
    return {
      x: Date.UTC(item.year, item.month),
      y: item.total,
      custom: {
        name: `${item.totalFormatted} ${currency}`,
        description: `+ ${item.returnsFormatted} ${currency}`
      }
    };
  }, data);
};

export const mapBankReturnsCharts = (data, translations) => {
  const currency = i18n('shared.currency', translations);

  return R.map(item => {
    return {
      x: Date.UTC(item.year, item.month),
      y: item.total_bank,
      custom: {
        name: `${item.totalBankFormatted} ${currency}`,
        description: `+ ${item.accumReturnsBankFormatted} ${currency}`
      }
    };
  }, data);
};

export const mapResultsTableData = data => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return R.filter(i => i.year > currentYear, data);
};

export const mapResultsBaseData = (cultureCode, data, initialDeposit) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return R.pipe(
    R.map(i => {
      const date = new Date(i.date);

      return { ...i, date, year: date.getFullYear(), month: date.getMonth() };
    }),
    R.filter(i => i.year > currentYear),
    R.append({
      date: new Date(currentYear, currentMonth),
      year: currentYear,
      month: currentMonth,
      accum_deposit: initialDeposit,
      accum_returns: 0,
      total_value: initialDeposit,
      total_bank: initialDeposit,
      accum_returns_bank: 0
    }),
    R.groupBy(R.prop('year')),
    R.toPairs,
    R.sort(R.ascend(R.prop(0))),
    R.map(i => {
      const items = i[1];
      const currentMonthItem = R.find(i => i.month === currentMonth, items);

      const total = currentMonthItem.total_value;
      const returns = currentMonthItem.accum_returns;
      const deposit = currentMonthItem.accum_deposit;
      const total_bank = currentMonthItem.total_bank;
      const accum_returns_bank = currentMonthItem.accum_returns_bank;
      const deposit_bank =
        currentMonthItem.total_bank - currentMonthItem.accum_returns_bank;

      return {
        id: currentMonthItem.year,
        date: currentMonthItem.date,
        year: currentMonthItem.year,
        month: currentMonthItem.month,
        dateFormatted: moment(
          new Date(currentMonthItem.year, currentMonthItem.month)
        ).format('MMM YYYY'),
        total,
        returns,
        deposit,
        total_bank,
        accum_returns_bank,
        deposit_bank,
        totalFormatted: formatNumber(cultureCode, total),
        returnsFormatted: formatNumber(cultureCode, returns),
        depositFormatted: formatNumber(cultureCode, deposit),
        totalBankFormatted: formatNumber(cultureCode, total_bank),
        accumReturnsBankFormatted: formatNumber(
          cultureCode,
          accum_returns_bank
        ),
        depositBankFormatted: formatNumber(cultureCode, deposit_bank)
      };
    })
  )(data);
};
