import React from 'react';
import { createUseStyles } from 'react-jss';

import quantfolioLogo from 'assets/quantfolioLogo.svg';
import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';

const useStyles = createUseStyles({
  root: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  label: { fontSize: '12px', color: '#D8D4D4' }
});

const PoweredBy = () => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {i18n('shared.poweredBy', customerConfig.translations)}
      </div>
      <img src={quantfolioLogo} />
    </div>
  );
};

export default PoweredBy;
