import create from 'zustand';

export const useDataStore = create(set => ({
  tableData: null,
  expectedReturnChartData: [],
  boundsChartData: [],
  horizon: null,
  setData: ({
    tableData,
    expectedReturnChartData,
    boundsChartData,
    horizon
  }) => {
    set({
      tableData,
      expectedReturnChartData,
      boundsChartData,
      horizon
    });
  }
}));
