import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import config from 'config/index.js';
import Application from 'features/main/components/index.js';

export const renderApplication = () => {
  ReactDOM.render(
    <Router>
      <Route component={Application}/>
    </Router>,
    document.getElementById(config.APP_ELEMENT_ID)
  );
};
