import React from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import Scrollbars from 'features/shared/components/scrollbars/index.js';

const styles = {
  menuContainer: {
    zIndex: 0,
    width: '100%'
  },
  menu: {
    width: '100%',
    backgroundColor: Colors.blue25,
    border: `2px solid ${Colors.blue150}`
  },
  scrollbarsContent: {},
  offsetBottom: {
    marginTop: '-2rem',
    paddingTop: '2.8rem',
    paddingBottom: '1.2rem',
    borderBottomLeftRadius: '1.6rem',
    borderBottomRightRadius: '1.6rem'
  },
  offsetTop: {
    marginBottom: '-2rem',
    paddingBottom: '2.8rem',
    paddingTop: '1.2rem',
    borderTopLeftRadius: '1.6rem',
    borderTopRightRadius: '1.6rem'
  },
  option: {
    padding: '0.8rem 2.4rem',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: Colors.blue150_05
    }
  },
  optionNoDataTitle: {
    cursor: 'default',
    color: Colors.gray50,

    '&:hover': {
      backgroundColor: Colors.blue25
    }
  }
};

const Menu = ({
  forwardedRef,
  style,
  placement,
  options,
  onSelect,
  onMouseDown,
  className,
  offsetBottomClassName,
  offsetTopClassName,
  optionClassName,
  noDataTitle,
  classes
}) => {
  return (
    <div
      ref={forwardedRef}
      className={classes.menuContainer}
      style={style}
      data-placement={placement}
    >
      <div
        className={classNames(
          classes.menu,
          {
            [classes.offsetBottom]: placement === 'bottom',
            [offsetBottomClassName]:
              !R.isNil(offsetBottomClassName) && placement === 'bottom',
            [classes.offsetTop]: placement === 'top',
            [offsetTopClassName]:
              !R.isNil(offsetTopClassName) && placement === 'top'
          },
          className
        )}
        tabIndex="0"
        onMouseDown={onMouseDown}
      >
        <Scrollbars>
          <div className={classes.scrollbarsContent}>
            {R.map(
              option => (
                <div
                  className={classNames(classes.option, optionClassName)}
                  key={option.key}
                  onClick={() => onSelect(option)}
                >
                  {option.title}
                </div>
              ),
              options
            )}
            {R.isEmpty(options) && (
              <div
                className={classNames(
                  classes.option,
                  classes.optionNoDataTitle,
                  optionClassName
                )}
              >
                {noDataTitle}
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default injectSheet(styles)(Menu);
