import React from 'react';
import { Scrollbars as ReactCustomScrollbars } from 'react-custom-scrollbars';

import { Colors } from 'features/shared/constants/colors.js';

const renderThumbVertical = ({ style, ...props }) => {
  const finalStyle = {
    ...style,
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: Colors.blue150_05
  };

  return <div style={finalStyle} {...props} />;
};

const renderThumbHorizontal = ({ style, ...props }) => {
  const finalStyle = {
    ...style,
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: Colors.blue150_05
  };

  return <div style={finalStyle} {...props} />;
};

const Scrollbars = ({ forwardedRef, ...restProps }) => {
  return (
    <ReactCustomScrollbars
      renderThumbVertical={renderThumbVertical}
      renderThumbHorizontal={renderThumbHorizontal}
      autoHeight
      autoHeightMax={400}
      ref={forwardedRef}
      {...restProps}
    />
  );
};

export default Scrollbars;
