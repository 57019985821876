import * as R from 'ramda';

export const qAuthApiAccessTokenKey = 'qAuthApi_accessToken';

export const setQAuthApiAccessToken = (accessToken, expiresTime) => {
  localStorage.setItem(
    qAuthApiAccessTokenKey,
    JSON.stringify({
      accessToken,
      expiresTime
    })
  );
};

export const getQAuthApiAccessToken = () => {
  return R.pipe(
    key => localStorage.getItem(key),
    item => {
      if (R.isNil(item)) {
        return null;
      }

      const tokenData = JSON.parse(item);
      const currentTime = new Date().getTime();

      return currentTime > tokenData.expiresTime ? null : tokenData.accessToken;
    }
  )(qAuthApiAccessTokenKey);
};

export const removeQAuthApiAccessToken = () => {
  localStorage.removeItem(qAuthApiAccessTokenKey);
};
