import React from 'react';
import { createUseStyles } from 'react-jss';
import * as R from 'ramda';
import MaterialSlider from '@material-ui/core/Slider';
import classNames from 'classnames';

let defaultConfig = {
  min: 0,
  max: 100
};

const useStyles = createUseStyles(theme => ({
  slider: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px'
  },
  root: {
    color: theme.sliderRootColor,
    height: '10px',
    padding: '10px 0',
    margin: '5px 0'
  },
  thumb: {
    height: '20px',
    width: '20px',
    backgroundColor: theme.sliderThumbColor,
    marginLeft: '-10px',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    },
    '&:focus': {
      backgroundColor: theme.sliderThumbFocusedColor
    },
    '&::after': {
      top: '0',
      bottom: '0',
      right: '0',
      left: '0'
    }
  },
  track: {
    height: '10px',
    borderRadius: '10px',
    background: theme.sliderTrackColor
  },
  rail: {
    opacity: '1',
    height: '10px',
    borderRadius: '10px'
  }
}));

const Slider = ({ className, config, value, callbackFn }) => {
  const classes = useStyles();
  let data = config ? config : defaultConfig;

  const handleChange = (event, newValue) => {
    callbackFn(newValue);
  };

  return (
    <div className={classNames(classes.slider, className)}>
      <MaterialSlider
        disabled={R.isNil(data.min) || R.isNil(data.max)}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail
        }}
        value={value}
        min={data.min}
        max={data.max}
        step={data.step}
        onChange={handleChange}
      />
    </div>
  );
};

export default Slider;
