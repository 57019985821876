import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import { formatNumber } from 'features/shared/utils/number.js';
import I18n from 'features/shared/components/i18n/index.js';
import { useDataStore } from '../services/dataStore.js';

const useStyles = createUseStyles(theme => ({
  header: {
    flex: '0 0 auto'
  },
  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '10px'
  },
  valueContainer: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    display: 'flex',
    alignItems: 'baseline'
  },
  valueContainerNumber: {
    color: theme.primaryColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.bold,
    fontSize: TextFontSizes.xxLargePx,
    lineHeight: TextFontSizes.xxLargeLineHeightPx,
    flex: '0 1 auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '&$mobileValueContainerNumber': {
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx
    }
  },
  mobileValueContainerNumber: {},
  valueContainerCurrency: {
    flex: '0 0 auto',
    paddingLeft: '10px'
  }
}));

const ExpectedResultCard = ({ className, isMobile }) => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const dataStore = useDataStore();
  const expectedResultValue =
    R.isNil(dataStore.tableData) || R.isEmpty(dataStore.tableData)
      ? null
      : formatNumber(
          customerConfig.cultureCode,
          R.last(dataStore.tableData).expectedReturn
        );

  return (
    !R.isNil(dataStore.horizon) &&
    !R.isNil(expectedResultValue) && (
      <div className={classNames(classes.header, className)}>
        <div className={classes.headerText}>
          <I18n
            text={i18n(
              'resultsCard.expectedResult',
              customerConfig.translations
            )}
            replaceParams={{
              '{0}': <span>{dataStore.horizon}</span>
            }}
          />
        </div>

        <div className={classes.valueContainer}>
          <div
            className={classNames(classes.valueContainerNumber, {
              [classes.mobileValueContainerNumber]: isMobile
            })}
            title={expectedResultValue}
          >
            {expectedResultValue}
          </div>
          <div className={classes.valueContainerCurrency}>
            {i18n('shared.currency', customerConfig.translations)}
          </div>
        </div>
      </div>
    )
  );
};

export default ExpectedResultCard;
