import create from 'zustand';

export const useFormStore = create(set => ({
  saving: 0,
  holding: 100000,
  horizon: 10,
  riskProfile: null,
  changeSaving: saving => {
    set({
      saving
    });
  },
  changeHolding: holding => {
    set({
      holding
    });
  },
  changeHorizon: horizon => {
    set({
      horizon
    });
  },
  changeRiskProfile: riskProfile => {
    set({
      riskProfile
    });
  }
}));
