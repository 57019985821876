import * as R from 'ramda';

export const filterServerData = (currentDate, data) => {
  const { month: currentMonth } = currentDate;
  const { projection } = data;

  return R.pipe(
    R.map(i => {
      const date = new Date(i.time);

      return {
        year: date.getFullYear(),
        month: date.getMonth(),
        total: i.total,
        percentiles: i.percentiles
      };
    }),
    R.groupBy(R.prop('year')),
    R.toPairs,
    R.sort(R.ascend(R.prop(0))),
    R.map(g => {
      const items = g[1];
      const currentMonthItem = R.find(i => i.month === currentMonth, items);

      return currentMonthItem;
    }),
    R.reject(R.isNil)
  )(projection);
};

export const mapServerDataToTable = (currentDate, data) => {
  const { year: currentYear } = currentDate;
  const filteredData = R.filter(
    i => i.year > currentYear,
    filterServerData(currentDate, data)
  );

  return R.map(
    i => ({
      year: i.year,
      month: i.month,
      expectedReturn: i.total,
      upperBound: Number(i.percentiles[2]),
      lowerBound: Number(i.percentiles[0])
    }),
    filteredData
  );
};

export const mapServerDataToExpectedReturnChart = (currentDate, data) => {
  const filteredData = filterServerData(currentDate, data);

  return R.map(i => [Date.UTC(i.year, i.month), i.total], filteredData);
};

export const mapServerDataToBoundsChart = (currentDate, data) => {
  const filteredData = filterServerData(currentDate, data);

  return R.map(
    i => [
      Date.UTC(i.year, i.month),
      Number(i.percentiles[0]),
      Number(i.percentiles[2])
    ],
    filteredData
  );
};
