import create from 'zustand';

export const useDataStore = create(set => ({
  returnsChartData: [],
  resultsTableData: null,
  resultsHorizon: null,
  setData: ({ returnsChartData, resultsTableData, resultsHorizon }) => {
    set({
      returnsChartData,
      resultsTableData,
      resultsHorizon
    });
  }
}));
