import React, { Fragment } from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import { formatNumber } from 'features/shared/utils/number.js';
import Table from 'features/shared/components/table/index.js';
import Link from 'features/shared/components/link/index.js';
import { useDataStore } from '../services/dataStore.js';
import { useUiStore } from '../services/uiStore.js';

const useStyles = createUseStyles(theme => ({
  table: {
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  moduleTypeCell: {
    verticalAlign: 'top',
    fontFamily: theme.tabularNumsFontFamily
  },
  moduleTypeContainer: {
    height: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  yearColumn: {
    width: '85px'
  },
  tableToolbar: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: '10px'
  },
  tableToolbarLabel: {
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginRight: '20px'
  },
  tableTopContainer: {
    height: '100%'
  },
  tableContainer: {
    height: '100%'
  },
  list: {
    marginLeft: '-20px',
    marginRight: '-20px',
    backgroundColor: Colors.white
  },
  listRow: {
    padding: '7px 20px',
    display: 'flex',
    alignItems: 'baseline',

    '& + &': {
      borderTop: `1px solid ${theme.dataListSeparatorColor}`
    }
  },
  listRowTitle: {
    color: theme.dataListTitleColor,
    fontWeight: FontWeights.semiBold,
    fontFamily: theme.tabularNumsFontFamily,
    flex: '0 0 auto',
    padding: '7px 0px',
    marginRight: '20px'
  },
  listRowSections: {
    flex: '1 1 auto'
  },
  listRowSection: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '7px 0px'
  },
  listRowSectionLabel: {
    flex: '0 1 50%',
    marginRight: '20px',
    color: theme.dataListHeaderColor,
    fontFamily: theme.fontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx
  },
  listRowSectionValue: {
    flex: '0 1 50%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: theme.tabularNumsFontFamily
  }
}));

const ResultsTableCard = ({ isMobile }) => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const dataStore = useDataStore();
  const uiStore = useUiStore();
  const expectedResultValue =
    R.isNil(dataStore.tableData) || R.isEmpty(dataStore.tableData)
      ? null
      : formatNumber(
          customerConfig.cultureCode,
          R.last(dataStore.tableData).expectedReturn
        );

  return (
    <Fragment>
      {!R.isNil(dataStore.horizon) && !R.isNil(expectedResultValue) && (
        <div className={classes.tableToolbar}>
          <span className={classes.tableToolbarLabel}>
            {i18n('resultsCard.table.header', customerConfig.translations)}
          </span>
          <Link
            onClick={() => {
              uiStore.toggleTable();
            }}
          >
            {uiStore.isTableOpen
              ? i18n('resultsCard.hideDetails', customerConfig.translations)
              : i18n('resultsCard.showDetails', customerConfig.translations)}
          </Link>
        </div>
      )}
      {uiStore.isTableOpen && !R.isNil(dataStore.tableData) && !isMobile && (
        <div className={classes.table}>
          <Table
            topConatinerClassName={classes.tableTopContainer}
            conatinerClassName={classes.tableContainer}
            header={[
              {
                title: i18n(
                  'resultsCard.table.header.year',
                  customerConfig.translations
                ),
                className: classes.yearColumn
              },
              {
                title: i18n(
                  'resultsCard.table.header.expectedReturn',
                  customerConfig.translations
                )
              },
              {
                title: i18n(
                  'resultsCard.table.header.upperBound',
                  customerConfig.translations
                )
              },
              {
                title: i18n(
                  'resultsCard.table.header.lowerBound',
                  customerConfig.translations
                )
              }
            ]}
            items={R.map(row => {
              const expectedReturn = formatNumber(
                customerConfig.cultureCode,
                row.expectedReturn
              );
              const upperBound = formatNumber(
                customerConfig.cultureCode,
                row.upperBound
              );
              const lowerBound = formatNumber(
                customerConfig.cultureCode,
                row.lowerBound
              );

              return {
                id: row.year,
                cells: ({ bodyRowCellClassName }) => (
                  <Fragment>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell,
                        classes.yearColumn
                      )}
                    >
                      <div className={classes.moduleTypeContainer}>
                        {row.year}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={expectedReturn}
                      >
                        {expectedReturn}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={upperBound}
                      >
                        {upperBound}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        bodyRowCellClassName,
                        classes.moduleTypeCell
                      )}
                    >
                      <div
                        className={classes.moduleTypeContainer}
                        title={lowerBound}
                      >
                        {lowerBound}
                      </div>
                    </td>
                  </Fragment>
                )
              };
            }, dataStore.tableData)}
          />
        </div>
      )}
      {uiStore.isTableOpen && !R.isNil(dataStore.tableData) && isMobile && (
        <div className={classes.list}>
          {R.map(row => {
            const expectedReturn = formatNumber(
              customerConfig.cultureCode,
              row.expectedReturn
            );
            const upperBound = formatNumber(
              customerConfig.cultureCode,
              row.upperBound
            );
            const lowerBound = formatNumber(
              customerConfig.cultureCode,
              row.lowerBound
            );

            return (
              <div key={row.year} className={classes.listRow}>
                <div className={classes.listRowTitle}>{row.year}</div>
                <div className={classes.listRowSections}>
                  <div className={classes.listRowSection}>
                    <div className={classes.listRowSectionLabel}>
                      {i18n(
                        'resultsCard.table.header.expectedReturn',
                        customerConfig.translations
                      )}
                    </div>
                    <div
                      className={classes.listRowSectionValue}
                      title={expectedReturn}
                    >
                      {expectedReturn}
                    </div>
                  </div>
                  <div className={classes.listRowSection}>
                    <div className={classes.listRowSectionLabel}>
                      {i18n(
                        'resultsCard.table.header.upperBound',
                        customerConfig.translations
                      )}
                    </div>
                    <div
                      className={classes.listRowSectionValue}
                      title={upperBound}
                    >
                      {upperBound}
                    </div>
                  </div>
                  <div className={classes.listRowSection}>
                    <div className={classes.listRowSectionLabel}>
                      {i18n(
                        'resultsCard.table.header.lowerBound',
                        customerConfig.translations
                      )}
                    </div>
                    <div
                      className={classes.listRowSectionValue}
                      title={lowerBound}
                    >
                      {lowerBound}
                    </div>
                  </div>
                </div>
              </div>
            );
          }, dataStore.tableData)}
        </div>
      )}
    </Fragment>
  );
};

export default ResultsTableCard;
