import React from 'react';
import classNames from 'classnames';

const Icon = ({ forwardedRef, className, type, ...rest }) => {
  return (
    <i
      ref={forwardedRef}
      className={classNames('material-icons', className)}
      {...rest}
    >
      {type}
    </i>
  );
};

export default Icon;
