import React from 'react';
import { createUseStyles } from 'react-jss';

import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import ReturnChart from './returnChart.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { useDataStore } from '../services/dataStore.js';

const useStyles = createUseStyles(theme => ({
  card: {
    backgroundColor: theme.cardBackgroundColor,
    boxShadow: '0px 0px 20px rgba(38, 50, 56, 0.08)',
    padding: '20px'
  },

  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '10px'
  },

  text: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    marginBottom: '20px'
  },

  chartLegendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '10px'
  },

  yourSavingPlan: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '20px',

    '& div': {
      backgroundColor: theme.returnChartLightColor,
      width: '30px',
      height: '17px',
      marginRight: '10px'
    },
    '& span': {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx
    }
  },

  savingInYourBankAccount: {
    display: 'flex',
    flexDirection: 'row',

    '& div': {
      backgroundColor: theme.returnChartDarkColor,
      width: '30px',
      height: '17px',
      marginRight: '10px'
    },
    '& span': {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx
    }
  }
}));

const ReturnChartCard = () => {
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const dataStore = useDataStore();

  return (
    <div className={classes.card}>
      <div>
        <div className={classes.headerText}>
          {i18n('returnChartCard.title', customerConfig.translations)}
        </div>

        <div className={classes.text}>
          {i18n('returnChartCard.description', customerConfig.translations)}
        </div>

        <ReturnChart
          data={dataStore.returnsChartData}
          styles={customerConfig.styles}
        />

        <div className={classes.chartLegendContainer}>
          <div className={classes.yourSavingPlan}>
            <div />
            <span>
              {i18n(
                'returnChartCard.yourSavingPlan',
                customerConfig.translations
              )}
            </span>
          </div>
          <div className={classes.savingInYourBankAccount}>
            <div />
            <span>
              {i18n(
                'returnChartCard.savingInYourBankAccount',
                customerConfig.translations
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnChartCard;
