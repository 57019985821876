import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import { OrderDirections } from 'features/shared/constants/table.js';
import Icon from 'features/shared/components/icon/index.js';
import Pagination from './pagination.js';
import Checkbox from 'features/shared/components/checkbox/index.js';

const indexedMap = R.addIndex(R.map);

const styles = theme => {
  return {
    container: {
      position: 'relative'
    },
    overlay: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: 10,
      backgroundColor: Colors.blue50_07
    },
    loadingIndicator: {
      position: 'absolute',
      top: '94px',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: 11,
      display: 'flex',
      justifyContent: 'center'
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',

      display: 'flex',
      flexFlow: 'column',
      height: '100%',

      '& thead': {
        flex: '0 0 auto',
        width: '100%'
      },

      '& tbody': {
        flex: '1 1 auto',
        display: 'block',
        overflowY: 'auto'
      },

      '& tbody tr': {
        width: '100%'
      },

      '& thead, tbody tr': {
        display: 'table',
        tableLayout: 'fixed'
      }
    },
    headerRowCell: {
      color: theme.tableHeaderColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      padding: '7px 20px',
      backgroundColor: theme.tableHeaderBackgroundColor,

      '&:first-child': {
        textAlign: 'start'
      },

      '&:not(:first-child)': {
        textAlign: 'end'
      }
    },
    sortingIcon: {
      marginLeft: '0.5rem',
      fontSize: '2rem',
      lineHeight: '2.4rem'
    },
    headerRowCellContent: {
      display: 'inline-flex',
      alignItems: 'center',
      userSelect: 'none',

      '&$sortable': {
        cursor: 'pointer'
      }
    },
    sortable: {},
    bodyRow: {
      '&$even $bodyRowCell': {
        backgroundColor: theme.tableEvenRowCellColor
      },
      '&$odd $bodyRowCell': {
        backgroundColor: theme.tableOddRowCellColor
      },

      '&$clickable': {
        cursor: 'pointer'
      },

      '&$noData': {
        color: Colors.gray50
      },

      '&:hover $bodyRowCell': {
        backgroundColor: theme.tableHoverColor
      }
    },
    noData: {},
    clickable: {},
    even: {},
    odd: {},
    bodyRowCell: {
      padding: '7px 20px',

      '&:not(:first-child)': {
        textAlign: 'end'
      }
    }
  };
};

const Table = ({
  isSelectionShown = false,
  orderKey,
  orderDirection,
  selectedRowIds,
  allRowIds,
  header,
  items,
  noDataTitle,
  isLoading = false,
  pageNumber,
  pageSize,
  totalItemsNumber,
  changeOrder,
  changePageNumber,
  changePageSize,
  changeRowSelection,
  changeAllRowsSelection,
  forwardedTableRef,
  forwardedTableContainerRef,
  classes,
  toolbar,
  topConatinerClassName,
  conatinerClassName
} = {}) => {
  selectedRowIds = R.isNil(selectedRowIds) ? [] : selectedRowIds;

  const cellsNumber = isSelectionShown ? header.length + 1 : header.length;

  const Toolbar = toolbar;

  return (
    <div className={topConatinerClassName}>
      {Toolbar && <Toolbar selectedRowIds={selectedRowIds} />}
      <div
        className={classNames(classes.container, conatinerClassName)}
        ref={forwardedTableContainerRef}
      >
        {isLoading && <div className={classes.overlay} />}
        {isLoading && (
          <div className={classes.loadingIndicator}>
            <CircularProgress style={{ color: Colors.blue150 }} />
          </div>
        )}
        <table className={classes.table} ref={forwardedTableRef}>
          <thead>
            <tr>
              {isSelectionShown && (
                <th className={classes.headerRowCell}>
                  <Checkbox
                    checked={
                      !R.isEmpty(allRowIds) &&
                      R.isEmpty(R.without(selectedRowIds, allRowIds))
                    }
                    onChange={e => {
                      changeAllRowsSelection(allRowIds, e.target.checked);
                    }}
                  />
                </th>
              )}
              {indexedMap((cell, index) => {
                return (
                  <th
                    className={classNames(
                      classes.headerRowCell,
                      cell.className
                    )}
                    key={index}
                  >
                    <a
                      className={classNames(classes.headerRowCellContent, {
                        [classes.sortable]: cell.isOrderable
                      })}
                      onClick={e => {
                        e.preventDefault();

                        if (cell.isOrderable) {
                          let newOrderDirection = OrderDirections.asc;

                          if (
                            orderKey === cell.orderKey &&
                            orderDirection === OrderDirections.asc
                          ) {
                            newOrderDirection = OrderDirections.desc;
                          }

                          if (
                            orderKey === cell.orderKey &&
                            orderDirection === OrderDirections.desc
                          ) {
                            newOrderDirection = OrderDirections.asc;
                          }

                          changeOrder(cell.orderKey, newOrderDirection);
                        }
                      }}
                    >
                      <span>{cell.title}</span>
                      {cell.isOrderable &&
                        orderKey === cell.orderKey &&
                        orderDirection === OrderDirections.asc && (
                          <Icon
                            className={classes.sortingIcon}
                            type="arrow_drop_up"
                          />
                        )}
                      {cell.isOrderable &&
                        orderKey === cell.orderKey &&
                        orderDirection === OrderDirections.desc && (
                          <Icon
                            className={classes.sortingIcon}
                            type="arrow_drop_down"
                          />
                        )}
                    </a>
                  </th>
                );
              }, header)}
            </tr>
          </thead>
          <tbody>
            {items.length === 0 && (
              <tr
                className={classNames(
                  classes.bodyRow,
                  classes.noData,
                  classes.even
                )}
              >
                <td className={classes.bodyRowCell} colSpan={cellsNumber}>
                  {noDataTitle}
                </td>
              </tr>
            )}
            {indexedMap(
              (item, index) => (
                <tr
                  key={item.id}
                  className={classNames(classes.bodyRow, {
                    [classes.even]: index % 2 === 0,
                    [classes.odd]: index % 2 !== 0,
                    [classes.clickable]: !R.isNil(item.onClick)
                  })}
                  onClick={item.onClick}
                >
                  {isSelectionShown && (
                    <td className={classes.bodyRowCell}>
                      <Checkbox
                        checked={R.includes(item.id, selectedRowIds)}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        onChange={e => {
                          changeRowSelection(item.id, e.target.checked);
                        }}
                      />
                    </td>
                  )}
                  {item.cells({ bodyRowCellClassName: classes.bodyRowCell })}
                </tr>
              ),
              items
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalItemsNumber={totalItemsNumber}
        changePageNumber={changePageNumber}
        changePageSize={changePageSize}
      />
    </div>
  );
};

export default injectSheet(styles)(Table);
