export const sliderConfig = {
  monthlySaving: { min: 0, max: 15000, step: 50 },
  oneTimeSaving: { min: 0, max: 1000000, step: 100 },
  horizon: { min: 1, max: 50, step: 1 }
};

export const inputConfig = {
  monthlySaving: { min: 0 },
  oneTimeSaving: { min: 0 },
  horizon: { min: 1 }
};
