import React from 'react';
import injectSheet from 'react-jss';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';

const styles = theme => {
  return {
    '@global': {
      html: {
        height: '100%'
      },
      body: {
        height: 'inherit',
        backgroundColor: theme.backgroundColor
          ? theme.backgroundColor
          : Colors.backgroundColor,
        color: theme.textColor ? theme.textColor : Colors.black,
        margin: 0,
        padding: 0,
        fontSize: TextFontSizes.mediumPx,
        lineHeight: TextFontSizes.mediumLineHeightPx,
        fontFamily: theme.fontFamily,
        fontWeight: FontWeights.normal
      },
      '#root': {
        height: 'initial'
      },
      '*': {
        boxSizing: 'border-box'
      }
    }
  };
};

const MainLayout = ({ children, pageContainer }) => {
  return <div className={pageContainer}>{children}</div>;
};

export default injectSheet(styles)(MainLayout);
