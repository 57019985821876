import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { CustomerConfigProvider } from 'features/sharedModules/customerConfig/components/index.js';
import CalculatorUc1 from 'features/calculatorUc1/components/index.js';
import CalculatorUc6 from 'features/calculatorUc6/components/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';

const App = () => {
  useEffect(() => {
    let height;
    const sendPostMessage = () => {
      if (height !== document.getElementById('root').offsetHeight) {
        height = document.getElementById('root').offsetHeight;
        window.parent.postMessage(
          {
            type: 'savingCalculator.resize',
            frameHeight: height
          },
          '*'
        );
      }
    };

    sendPostMessage();
    window.addEventListener('resize', sendPostMessage);
    return () => {
      window.removeEventListener('resize', sendPostMessage);
    };
  }, []);

  return (
    <CustomerConfigProvider>
      <Switch>
        <Route
          exact
          path={routeTemplates.home.config}
          render={({ match }) => {
            const { customerId } = match.params;

            return customerId ? (
              <Redirect to={routeTemplates.calculatorUc1.build(customerId)} />
            ) : null;
          }}
        />
        <Route
          path={routeTemplates.calculatorUc1.config}
          component={CalculatorUc1}
        />
        <Route
          path={routeTemplates.calculatorUc1Test.config}
          render={() => (
            <div
              style={{
                maxWidth: '1240px',
                margin: 'auto',
                paddingTop: '20px',
                paddingBottom: '20px'
              }}
            >
              <CalculatorUc1 />
            </div>
          )}
        />
        <Route
          path={routeTemplates.calculatorUc6.config}
          component={CalculatorUc6}
        />
        <Route
          path={routeTemplates.calculatorUc6Test.config}
          render={() => (
            <div
              style={{
                maxWidth: '1240px',
                margin: 'auto',
                paddingTop: '20px',
                paddingBottom: '20px'
              }}
            >
              <CalculatorUc6 />
            </div>
          )}
        />
      </Switch>
    </CustomerConfigProvider>
  );
};

export default App;
