import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.cardBackgroundColor,
    boxShadow: '0px 0px 20px rgba(38, 50, 56, 0.08)',
    padding: '20px'
  }
}));

const Card = ({ className, style, children }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)} style={style}>
      {children}
    </div>
  );
};

export default Card;
