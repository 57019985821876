import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { i18n } from 'i18n/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import ReturnChart from './returnChart.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import { useDataStore } from '../services/dataStore.js';

const useStyles = createUseStyles(theme => ({
  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '10px'
  },
  text: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    marginBottom: '20px'
  },
  chartLegendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '10px'
  },
  yourSavingPlan: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '20px',

    '& div': {
      backgroundColor: theme.returnChartExpectedReturnColor,
      width: '30px',
      height: '17px',
      marginRight: '10px'
    },
    '& span': {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx
    }
  },
  savingInYourBankAccount: {
    display: 'flex',
    flexDirection: 'row',

    '& div': {
      backgroundColor: theme.returnChartBoundsColor,
      width: '30px',
      height: '17px',
      marginRight: '10px'
    },
    '& span': {
      color: theme.textColor,
      fontFamily: theme.fontFamily,
      fontWeight: FontWeights.normal,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx
    }
  }
}));

const ReturnChartCard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const dataStore = useDataStore();
  const chartsData = React.useMemo(
    () => [
      {
        id: 'expectedReturn',
        type: 'line',
        zIndex: 1,
        color: theme.returnChartExpectedReturnColor,
        custom: {
          type: i18n(
            'returnChartCard.expectedReturn',
            customerConfig.translations
          ),
          isMean: true
        },
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        },
        data: dataStore.expectedReturnChartData
      },
      {
        id: 'bounds',
        type: 'arearange',
        zIndex: 0,
        fillColor: theme.returnChartBoundsColor,
        lineColor: theme.returnChartBoundsColor,
        lineWidth: 0,
        data: dataStore.boundsChartData,
        custom: {
          type: i18n(
            'returnChartCard.upperAndLowerBound',
            customerConfig.translations
          ),
          isMean: false
        },
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        }
      }
    ],
    [dataStore.expectedReturnChartData, dataStore.boundsChartData]
  );

  return (
    <div>
      <div className={classes.headerText}>
        {i18n('returnChartCard.title', customerConfig.translations)}
      </div>

      <div className={classes.text}>
        {i18n('returnChartCard.description', customerConfig.translations)}
      </div>

      <ReturnChart
        data={chartsData}
        cultureCode={customerConfig.cultureCode}
        styles={customerConfig.styles}
      />

      <div className={classes.chartLegendContainer}>
        <div className={classes.yourSavingPlan}>
          <div />
          <span>
            {i18n(
              'returnChartCard.expectedReturn',
              customerConfig.translations
            )}
          </span>
        </div>
        <div className={classes.savingInYourBankAccount}>
          <div />
          <span>
            {i18n(
              'returnChartCard.upperAndLowerBound',
              customerConfig.translations
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReturnChartCard;
