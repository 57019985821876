import * as R from 'ramda';

import { NodeType } from '../constants/i18n.js';

const reduceIndexed = R.addIndex(R.reduce);

export const toAst = (replaceParams, text) => {
  let keysWithIndexes = R.pipe(
    R.keys,
    R.map(key => {
      return {
        key,
        value: replaceParams[key],
        index: text.indexOf(key)
      };
    }),
    R.filter(key => key.index !== -1),
    R.sortBy(key => key.index)
  )(replaceParams);
  if (keysWithIndexes.length === 0) {
    return [
      {
        type: NodeType.text,
        value: text
      }
    ];
  }
  let reducedKeys = reduceIndexed(
    (acc, key, index, list) => {
      let ast = acc.ast;
      if (acc.index < key.index) {
        ast = R.append(
          {
            type: NodeType.text,
            value: text.slice(acc.index, key.index)
          },
          ast
        );
      }
      ast = R.append(
        {
          type: NodeType.node,
          value: key.value
        },
        ast
      );
      let nextKey = key.index + key.key.length;
      if (index === list.length - 1 && nextKey < text.length) {
        ast = R.append(
          {
            type: NodeType.text,
            value: text.slice(nextKey)
          },
          ast
        );
        nextKey = text.length;
      }
      return {
        index: nextKey,
        ast
      };
    },
    {
      index: 0,
      ast: []
    },
    keysWithIndexes
  );
  return reducedKeys.ast;
};
