import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { i18n } from 'i18n/index.js';
import { getUc1SavingCalculations } from 'features/shared/api/index.js';
import { CustomerConfigContext } from 'features/sharedModules/customerConfig/components/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import Slider from 'features/shared/components/slider/index.js';
import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { getNumberInputFormat } from 'features/shared/utils/number.js';
import BaseTextInput from 'features/shared/components/input/baseTextInput.js';
import {
  mapReturnsCharts,
  mapBankReturnsCharts,
  mapResultsBaseData,
  mapResultsTableData
} from '../services/mapping.js';
import { inputConfig, sliderConfig } from '../constants.js';
import { useDataStore } from '../services/dataStore.js';
import { useFormStore } from '../services/formStore.js';

const useNumberInputStyles = createUseStyles(theme => ({
  root: {
    fontFamily: theme.tabularNumsFontFamily
  }
}));

const NumberInput = ({ className, ...restProps }) => {
  const classes = useNumberInputStyles();

  return (
    <BaseTextInput
      className={classNames(classes.root, className)}
      {...restProps}
    />
  );
};

const useStyles = createUseStyles(theme => ({
  card: {
    backgroundColor: theme.cardBackgroundColor,
    boxShadow: '0px 0px 20px rgba(38, 50, 56, 0.08)',
    padding: '20px'
  },

  headerText: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.medium,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    marginBottom: '20px'
  },

  text: {
    color: theme.textColor,
    fontFamily: theme.fontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    marginBottom: '7px'
  },

  segment: {
    paddingBottom: '20px'
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  slider: {
    flex: '1 1 auto',
    maxWidth: '550px'
  }
}));

const isSavingValid = value => !R.isNil(value) && value >= 0;

const isHoldingValid = value => !R.isNil(value) && value >= 0;

const isReturnsValid = value => !R.isNil(value) && value >= 0;

const isHorizonValid = value => !R.isNil(value) && value > 0;

const SettingsCard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const customerConfig = React.useContext(CustomerConfigContext);
  const { thousandSeparator, decimalSeparator } = getNumberInputFormat(
    customerConfig.cultureCode
  );
  const { customerId } = useParams();
  const dataStore = useDataStore();
  const formStore = useFormStore();

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const timeout = setTimeout(async () => {
      const { saving, holding, returns, horizon } = formStore;

      if (
        isSavingValid(saving) &&
        isHoldingValid(holding) &&
        isReturnsValid(returns) &&
        isHorizonValid(horizon)
      ) {
        let data = {
          saving: saving,
          holding: holding,
          return: returns / 100,
          horizon_in_years: horizon,
          bank_return: 0.01
        };

        try {
          const accessToken = await getQAuthAccessToken(
            customerId,
            cancelSource.token
          );

          const response = await getUc1SavingCalculations(
            data,
            accessToken,
            cancelSource.token
          );

          const baseData = mapResultsBaseData(
            customerConfig.cultureCode,
            response.data.projection,
            data.holding
          );

          dataStore.setData({
            returnsChartData: [
              {
                fillColor: theme.returnChartLightColor,
                lineColor: theme.returnChartLightColor,
                data: mapReturnsCharts(baseData, customerConfig.translations)
              },
              {
                fillColor: theme.returnChartDarkColor,
                lineColor: theme.returnChartDarkColor,
                data: mapBankReturnsCharts(
                  baseData,
                  customerConfig.translations
                )
              }
            ],
            resultsTableData: mapResultsTableData(baseData),
            resultsHorizon: formStore.horizon
          });
        } catch (error) {
          if (!axios.isCancel(error)) {
            throw error;
          }
        }
      }
    }, 300);

    return () => {
      cancelSource.cancel();
      clearTimeout(timeout);
    };
  }, [
    formStore.saving,
    formStore.holding,
    formStore.returns,
    formStore.horizon
  ]);

  return (
    <div className={classes.card}>
      <div>
        <div className={classes.headerText}>
          {i18n('settingsCard.title', customerConfig.translations)}
        </div>

        <div className={classes.segment}>
          <div className={classes.text}>
            {i18n('settingsCard.howMuchSave', customerConfig.translations)}
          </div>
          <div className={classes.inputs}>
            <Slider
              className={classes.slider}
              value={formStore.saving || 0}
              config={sliderConfig.monthlySaving}
              callbackFn={val => {
                formStore.changeSaving(val);
              }}
            />
            <NumberFormat
              customInput={NumberInput}
              value={formStore.saving}
              onValueChange={values => {
                const floatValue = R.isNil(values.floatValue)
                  ? values.floatValue
                  : Math.min(values.floatValue, inputConfig.monthlySaving.max);

                formStore.changeSaving(floatValue);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={3}
              allowNegative={false}
              isInvalid={!isSavingValid(formStore.saving)}
            />
          </div>
        </div>

        <div className={classes.segment}>
          <div className={classes.text}>
            {i18n(
              'settingsCard.doYouHaveSavingsToInvest',
              customerConfig.translations
            )}
          </div>
          <div className={classes.inputs}>
            <Slider
              className={classes.slider}
              value={formStore.holding || 0}
              config={sliderConfig.oneTimeSaving}
              callbackFn={val => {
                formStore.changeHolding(val);
              }}
            />
            <NumberFormat
              customInput={NumberInput}
              value={formStore.holding}
              onValueChange={values => {
                const floatValue = R.isNil(values.floatValue)
                  ? values.floatValue
                  : Math.min(values.floatValue, inputConfig.oneTimeSaving.max);

                formStore.changeHolding(floatValue);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={3}
              allowNegative={false}
              isInvalid={!isHoldingValid(formStore.holding)}
            />
          </div>
        </div>

        <div className={classes.segment}>
          <div className={classes.text}>
            {i18n(
              'settingsCard.whatIsExpectedYearlyReturn',
              customerConfig.translations
            )}
          </div>
          <div className={classes.inputs}>
            <Slider
              className={classes.slider}
              value={formStore.returns || 0}
              config={sliderConfig.expectedReturn}
              callbackFn={val => {
                formStore.changeReturns(val);
              }}
            />
            <NumberFormat
              customInput={NumberInput}
              value={formStore.returns}
              onValueChange={values => {
                const floatValue = R.isNil(values.floatValue)
                  ? values.floatValue
                  : Math.min(values.floatValue, inputConfig.expectedReturn.max);

                formStore.changeReturns(floatValue);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={3}
              allowNegative={false}
              isInvalid={!isReturnsValid(formStore.returns)}
              suffix={'%'}
            />
          </div>
        </div>

        <div>
          <div className={classes.text}>
            {i18n(
              'settingsCard.howManyYearsDoYouWantSave',
              customerConfig.translations
            )}
          </div>
          <div className={classes.inputs}>
            <Slider
              className={classes.slider}
              value={formStore.horizon || 0}
              config={sliderConfig.horizon}
              callbackFn={val => {
                formStore.changeHorizon(val);
              }}
            />
            <NumberFormat
              customInput={NumberInput}
              value={formStore.horizon}
              onValueChange={values => {
                const floatValue = R.isNil(values.floatValue)
                  ? values.floatValue
                  : Math.min(values.floatValue, inputConfig.horizon.max);

                formStore.changeHorizon(floatValue);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={0}
              allowNegative={false}
              isInvalid={!isHorizonValid(formStore.horizon)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsCard;
